import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ReverseAuthGuard } from "./core/guards/reverse-auth.guard";
import { LevelGuard } from "./core/guards/level.guard";
import { GeographyGuard } from "./core/guards/geography.guard";
import { TenantGuard } from "./core/guards/tenant.guard";
import { AdminGuard } from "./core/guards/admin.guard";
import { ProfitCenterGuard } from "./core/guards/profit-center.guard";
import { ReportsGuard } from "./core/guards/reports.guard";
import { AdoptionStatsReportGuard } from "./core/guards/adoption-stats-reports.guard";

const routes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("../app/public/login/login.module").then((m) => m.LoginModule),
    canActivate: [ReverseAuthGuard],
  },
  {
    path: "sales-forecast",
    loadChildren: () =>
      import("../app/public/okta-login/okta-login.module").then(
        (m) => m.OktaLoginModule
      ),
    canActivate: [ReverseAuthGuard],
  },
  {
    path: "reports",
    loadChildren: () =>
      import("../app/private/reports/reports.module").then(
        (m) => m.ReportsModule
      ),
    canActivate: [ReportsGuard],
  },
  {
    path: "adoption-stats-reports",
    loadChildren: () =>
      import(
        "../app/private/adoption-stats-reports/adoption-stats-reports.module"
      ).then((m) => m.AdoptionStatsReportsModule),
    canActivate: [AdoptionStatsReportGuard],
  },
  {
    path: "level",
    loadChildren: () =>
      import("./private/level-center/level-center.module").then(
        (m) => m.LevelCenterModule
      ),
    runGuardsAndResolvers: "always",
    canActivate: [LevelGuard],
  },
  {
    path: "error",
    loadChildren: () =>
      import("./private/error-code/error-code.module").then(
        (m) => m.ErrorCodeModule
      ),
    // runGuardsAndResolvers: "always",
    // canActivate: [ReverseAuthGuard]
  },
  {
    path: "profit-center",
    loadChildren: () =>
      import("./private/profit-center/profit-center.module").then(
        (m) => m.ProfitCenterModule
      ),
    canActivate: [ProfitCenterGuard],
  },
  {
    path: "geography",
    loadChildren: () =>
      import("../app/private/goegraphy-center/geography-center.module").then(
        (m) => m.GeographyCenterModule
      ),
    canActivate: [GeographyGuard],
  },
  {
    path: "admin",
    loadChildren: () =>
      import("../app/private/admin/admin.module").then((m) => m.AdminModule),
    canActivate: [AdminGuard],
  },
  {
    path: "swal",
    loadChildren: () =>
      import("./private/tenants/common-tenant/common-tenant.module").then(
        (m) => m.CommonTenantModule
      ),
    canActivate: [TenantGuard],
  },
  {
    path: "af",
    loadChildren: () =>
      import("./private/tenants/common-tenant/common-tenant.module").then(
        (m) => m.CommonTenantModule
      ),
    canActivate: [TenantGuard],
  },
  {
    path: "mexico",
    loadChildren: () =>
      import("./private/tenants/mexico/mexico.module").then(
        (m) => m.MexicoModule
      ),
    canActivate: [TenantGuard],
  },
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "**",
    redirectTo: "login",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { COLUMN_HEADER } from "../constants/month.constants";

@Injectable({
  providedIn: "root",
})
export class LanguagesService {
  constructor(private http: HttpClient) {}

  getAllLanguages(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/languages`).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getLabelsFromLangId(selectedLanguage): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/v1/languages/${selectedLanguage}/labels`)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  updateLanguagePreferences(langPrefered): Observable<any> {
    return this.http
      .post(
        `${environment.apiUrl}/api/v1/languages/update/preference`,
        langPrefered
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  updateColumnHeaderLanguage(columnName) {
    columnName.name = COLUMN_HEADER.find(
      (columnNameValue) => columnNameValue.value === columnName.name
    )
      ? COLUMN_HEADER.find(
          (columnNameValue) => columnNameValue.value === columnName.name
        ).key
      : columnName.name;

    return columnName;
  }
}

export const CAN_VIEW_FINANCIAL_YEAR = 'CAN_VIEW_FINANCIAL_YEAR';
export const CAN_VIEW_MATERIAL_GROUP = 'CAN_VIEW_MATERIAL_GROUP';
export const CAN_VIEW_PRODUCT_BRAND = 'CAN_VIEW_PRODUCT_BRAND';
export const CAN_VIEW_ERROR_CATEGORY = 'CAN_VIEW_ERROR_CATEGORY';
export const CAN_VIEW_SKU = 'CAN_VIEW_SKU';
export const CAN_VIEW_TERRITORY = 'CAN_VIEW_TERRITORY';
export const CAN_VIEW_REGION = 'CAN_VIEW_REGION';
export const CAN_VIEW_ZONE = 'CAN_VIEW_ZONE';
export const CAN_DOWNLOAD_REPORT='CAN_DOWNLOAD_REPORT';
export const CAN_VIEW_SBU = 'CAN_VIEW_SBU';
export const CAN_VIEW_SKU_MATERIAL = 'CAN_VIEW_SKU_MATERIAL';
export const CAN_VIEW_MOLECULE = 'CAN_VIEW_MOLECULE';
export const CAN_VIEW_CUSTOMER = 'CAN_VIEW_CUSTOMER';
export const CAN_VIEW_AREA = 'CAN_VIEW_AREA';
export const CAN_VIEW_STATIC_OPQ = 'CAN_VIEW_STATIC_OPQ';
export const CAN_VIEW_DYNAMIC_ABC = 'CAN_VIEW_DYNAMIC_ABC';
//access roleList for admin
export const CAN_PROCESS_INPUT_FILE='CAN_PROCESS_INPUT_FILE';
export const CAN_VIEW_INPUT_FILE='CAN_VIEW_INPUT_FILE';
export const CAN_DOWNLOAD_INPUT_FILE_ERROR='CAN_DOWNLOAD_INPUT_FILE_ERROR';
export const CAN_VIEW_TRANSACTIONAL_INPUT_FILE='CAN_VIEW_TRANSACTIONAL_INPUT_FILE';
export const CAN_VIEW_MASTER_INPUT_FILE='CAN_VIEW_MASTER_INPUT_FILE';
export const CAN_PROCESS_TRANSACTIONAL_INPUT_FILE='CAN_PROCESS_TRANSACTIONAL_INPUT_FILE';
export const CAN_PROCESS_MASTER_INPUT_FILE='CAN_PROCESS_MASTER_INPUT_FILE';

export const CAN_CREATE_WINDOW='CAN_CREATE_WINDOW';
export const CAN_VIEW_WINDOW='CAN_VIEW_WINDOW';
export const CAN_EDIT_WINDOW='CAN_EDIT_WINDOW';

export const CAN_DOWNLOAD_OUTPUT_FILE='CAN_DOWNLOAD_OUTPUT_FILE';
export const CAN_VIEW_OUTPUT_FILE='CAN_VIEW_OUTPUT_FILE';
export const CAN_PUSH_OUTPUT_FILE='CAN_PUSH_OUTPUT_FILE';

export const CAN_VIEW_FORECASTING_SHEET='CAN_VIEW_FORECASTING_SHEET';
export const CAN_VIEW_ADOPTION_STATS='CAN_VIEW_ADOPTION_STATS';
export const CAN_DOWNLOAD_ADOPTION_STATS='CAN_DOWNLOAD_ADOPTION_STATS';

export const CAN_VIEW_REPORT='CAN_VIEW_REPORT';

export const CAN_CREATE_NOTIFICATION='CAN_CREATE_NOTIFICATION';
export const CAN_VIEW_NOTIFICATION='CAN_VIEW_NOTIFICATION';

export const CAN_CONFIGURE_CYCLE='CAN_CONFIGURE_CYCLE';
export const CAN_CHANGE_LANGUAGE='CAN_CHANGE_LANGUAGE';


export const CAN_VIEW_USERS='CAN_VIEW_USERS';
export const CAN_ADD_USER='CAN_ADD_USER';
export const CAN_EDIT_USER='CAN_EDIT_USER';
export const CAN_DELETE_USER='CAN_DELETE_USER';

export const CAN_VIEW_USER_ACTIVITIES='CAN_VIEW_USER_ACTIVITIES';
export const CAN_DOWNLOAD_USER_ACTIVITIES='CAN_DOWNLOAD_USER_ACTIVITIES';
export const CAN_VIEW_FORECASTING_CYCLE='CAN_VIEW_FORECASTING_CYCLE';
export const CAN_DOWNLOAD_FORECASTING_CYCLE_ACTIVITIES='CAN_DOWNLOAD_FORECASTING_CYCLE_ACTIVITIES';
export const CAN_CLOSE_CYCLE='CAN_CLOSE_CYCLE';

export const DEFAULT_VOLUME_UNIT = 'KG-LT';
export const DEFAULT_PRICE_UNIT = 'Lakhs';

export const ABSOLUTE = 'Absolute';
export const LOGIN005='LOGIN005';
export const WINDO011='WINDO011';
export const LOGINEXPIRED='LOGINEXPIRED';

export const LAKHS = 'Lakhs';
export const CRORES = 'Crores';
export const MILLIONS = 'Millions';
export const KGLT = 'KG-LT';
export const MTKL = 'MT-KL';
export const NET = "NET";
export const RETURN = "RETURN";
export const GROSS = "GROSS";
export const PENDING='PENDING'
export const COMPLETED='COMPLETED'

export const REGION = 'Region';
export const TERRITORY = 'Territory';
export const ZONE = 'ZONE';
export const PC = 'Profit Center'
export const SBU = 'SBU'

export const REGEXP = {
    'ONLY_NUMBERS_WITH_DECIMAL': '^[0-9]+([.][0-9]+)?$'
}

export const PCADMIN = 'PC Admin';

export const PARTICULARS='PARTICULARS';
export const PARTICULARS1='PARTICULARS1';
export const PARTICULARS2='PARTICULARS2';
export const TOTAL='TOTAL';
export const ASC='asc';
export const DESC='desc';
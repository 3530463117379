import { EventEmitter, Injectable } from "@angular/core";
 @Injectable({
  providedIn: "root",
})
export class SkuSplitsBasedService {
  _closeSkuSplitsBased: EventEmitter<any> = new EventEmitter(true);
  _toggleSKU: EventEmitter<any> = new EventEmitter(true);
  _toggleFinYear: EventEmitter<any> = new EventEmitter(true);
  _filterParamsData: EventEmitter<any> = new EventEmitter(true);

  data: any;
  financialYearList: any;
  selectedFinancialYear: any;
  forecastingSheetData: any;
  showLanguage: string;
  userLevel: any;
  filterParamsData: any;
  levelId: number;
  tmFiscalMonth:any;
  SkuListTm:any;


  constructor() {}

  getSkuSplitsBasedData() {
    return this.data;
  }

  setSkuSplitsBasedData(data: any) {
    this.data = data;
  }

  setFinacialYearList(data: any) {
    this.financialYearList = data;
  }

  getFinacialYearList() {
    return this.financialYearList;
  }

  setSelectedFinancialYear(data: any) {
    this.selectedFinancialYear = data;
  }

  getSelectedFinancialYear() {
    return this.selectedFinancialYear;
  }

  getAllForecastingSheetData() {
    return this.forecastingSheetData;
  }

  setAllForecastingSheetData(data: any) {
    this.forecastingSheetData = data;
  }

  setLanguage(data: any) {
    this.showLanguage = data;
  }

  getLanguage() {
    return this.showLanguage;
  }

  setUserLevel(data: any) {
    this.userLevel = data;
  }

  getUserLevel() {
    return this.userLevel;
  }

  getFilterParamsData() {
    return this.filterParamsData;
  }

  setFilterParamsData(data: any) {
    this.filterParamsData = data;
  }
  getLevelId() {
    return this.levelId;
  }
  setLevelId(data: any) {
    this.levelId = data;
  }

  getTmFiscalMonth() {
    return this.tmFiscalMonth;
  }
  setTmFiscalMonth(data: any) {
    this.tmFiscalMonth = data;
  }
  getTmSkuList() {
    return this.SkuListTm;
  }
  setTmSkuList(data: any) {
    this.SkuListTm = data;
  }

}

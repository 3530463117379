import {
  Component,
  OnInit,
  Input,
  ViewChild,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewEncapsulation,
  TemplateRef,
  ElementRef,
  ChangeDetectionStrategy,
} from "@angular/core";
import { HotTableComponent, HotTableRegisterer } from "@handsontable/angular";
import Handsontable from "handsontable";
import { getCurrentMonth } from "src/app/core/helpers/getCurrentMonth.helper";
import { MONTHS } from "src/app/core/constants/month.constants";
import { NgbCollapse, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TenantService } from "src/app/core/services/tenant.service";
import { Languages } from "src/app/core/constants/languages.constants";
import { AF, MEXICO, PC, SWAL } from "src/app/core/constants/tenant.constants";
import {
  NET,
  PARTICULARS,
  PARTICULARS1,
  PARTICULARS2,
  TOTAL,
} from "src/app/core/constants/string.constants";
import { RETURN, GROSS } from "src/app/core/constants/string.constants";
import { ToastrMessageService } from "src/app/core/services/toast-message.service";
import { MatDialog, MatDialogRef } from "@angular/material";
import { SkuSplitsBasedDialogComponent } from "src/app/shared/sku-splits-based-dialog/sku-splits-based-dialog.component";
import { SkuSplitsBasedService } from "src/app/core/services/sku-splits-based.service";
import { element } from "protractor";

@Component({
  selector: "app-common-sku-splits-based",
  templateUrl: "./common-sku-splits-based.component.html",
  styleUrls: ["./common-sku-splits-based.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CommonSkuSplitsBasedComponent implements OnInit, OnChanges {
 
  @Input() closeSkuDialog: boolean = false;
  @Output() closeDialog = new EventEmitter<void>();

  @ViewChild("SkuSplitsBasedDialog", { static: false })
  SkuSplitsBasedDialog: TemplateRef<any>;
  SkuSplitsBasedDialogRef: MatDialogRef<any>;
  @ViewChild("skuSplitHot", { static: false }) skuSplitHot: HotTableComponent;
  @ViewChild("skuSplitHotDialog", { static: false })
  skuSplitHotDialog: ElementRef;

  @Input() data;
  @Input() selectedFinancialYear;
  @Input() profile;
  @Input() isDataEdited;
  @Input() skuPriceMaster;
  @Input() canEditTable;
  @Input() userLevel;
  @Input() isSameUOM;
  @Input() selectedMaterialGroups;
  @Input() selectedAreas;
  @Input() selectProductLength;
  @Input() baseValueDataObject;
  @Input() threshold;
  @Input() page;
  @Input() totalPageSize;
  @Input() financialYearList;
  @Output() dataEdited = new EventEmitter();
  @Output() updateRevenue = new EventEmitter();
  @Output() forecastType = new EventEmitter<string>();
 
  @Output() finYearChange = new EventEmitter();
  @Output() editedSkuCodes = new EventEmitter();
  editedSkuList = [];
  negativeValueFlag = false;
  languages = Languages;
  hotSettings: Handsontable.GridSettings = null;
  hotData = null;
  hotID = "SKUHot";
  monthsList: any[] = MONTHS;
  previousValue = 0;
  isCollapsed = false;
  selectedForecast = GROSS;
  NET = NET;
  tenant;
  convertedBaseValues: any;
  convertedNegativeBaseValues: any;
  negativeBaseValueDataObject: any;
  positiveBaseValueDataObject: any;
  eMonths: any;
  editableObj: any = [];
  dialogRef: MatDialogRef<any>;
  enableEditButton: boolean = false;
  filterParamsData;
  assignedLevel;
  level;
  constructor(
    public hotRegisterer?: HotTableRegisterer,
    private chRef?: ChangeDetectorRef,
    private tenantService?: TenantService,
    private dialog?: MatDialog,
    private SkuSplitsBasedService?: SkuSplitsBasedService
  ) {
    this.assignedLevel = localStorage.getItem("assignedLevel");
    this.level = localStorage.getItem("level");
    this.tenant = this.tenantService.getTenant(); 
    this.selectedForecast = this.tenant === MEXICO ? "" : GROSS;
    this.SkuSplitsBasedService.setLanguage(this.languages);
  }
  editSkuSplitsBased(data: any) {
    const dialogRef = this.dialog.open(SkuSplitsBasedDialogComponent, {
      width: "100%",
      maxWidth: "100%",
      height: "100%",
      maxHeight: "100%",
      // disableClose: true,
      panelClass: "sku-splits-based-dialog",
      data: this.data,
      // hasBackdrop: true,
    });
  }
 
  closeSkuSplitsBasedDialog() {
    this.closeDialog.emit();
    this.SkuSplitsBasedService._closeSkuSplitsBased.emit('true');
  }
  ngOnChanges(changes: SimpleChanges) {

     const thresholdValue = this.threshold;
    this.negativeBaseValueDataObject = JSON.parse(
      JSON.stringify(this.baseValueDataObject)
    );
    this.positiveBaseValueDataObject = JSON.parse(
      JSON.stringify(this.baseValueDataObject)
    );
    if (changes && changes.data && changes.data.currentValue) {
      if (this.baseValueDataObject && this.baseValueDataObject.data) {
        //function to calculate positive basevalues
        this.convertedBaseValues = this.calculateBaseValue(
          this.positiveBaseValueDataObject,
          thresholdValue,
          changes.data.currentValue
        );
        //function to calculate negative basevalues
        this.convertedNegativeBaseValues = this.calculateNegativeBaseValue(
          this.negativeBaseValueDataObject,
          thresholdValue,
          changes.data.currentValue
        );
      }
      this.hotSettings = null; 
       this.generateTable( changes.data.currentValue); 
     }
  }
  calculateBaseValue(
    baseValueDataObject1: any,
    thresholdValue: number,
    skudata: any
  ) {
    if (baseValueDataObject1) {
      baseValueDataObject1.data.forEach((element, index) => {
        if (index >= 0) {
          Object.entries(element).forEach(([key, value], index1) => {
            if (this.isNumber(value) && index1 > 2 && index1 < 15) {
              const newValue: any = Number(value);
              element[key] =
                newValue != 0
                  ? +(newValue + (thresholdValue * newValue) / 100).toFixed(2)
                  : 0;
            }
          });
        }
      });
    }
    return baseValueDataObject1;
  }

  calculateNegativeBaseValue(
    baseValueDataObject1: any,
    thresholdValue: number,
    skudata: any
  ) {
    if (baseValueDataObject1) {
      baseValueDataObject1.data.forEach((element, index) => {
        if (index >= 0) {
          Object.entries(element).forEach(([key, value], index1) => {
            if (this.isNumber(value) && index1 > 2 && index1 < 15) {
              const newValue: any = Number(value);
              element[key] =
                newValue != 0
                  ? +(newValue - (thresholdValue * newValue) / 100).toFixed(2)
                  : 0;
            }
          });
        }
      });
    }
    return baseValueDataObject1;
  }

  ngOnInit() {
    const foreCastType = localStorage.getItem("forecastType")
      ? localStorage.getItem("forecastType")
      : GROSS;

    this.selectedForecast =
      this.tenant === MEXICO ? "" : foreCastType.toUpperCase();
    this.SkuSplitsBasedService.setSkuSplitsBasedData(this.data);
  }
  toggleFinYear(year) {
    if (year.id !== "All") {
      this.finYearChange.emit(year);
      this.SkuSplitsBasedService._toggleFinYear.emit(this.finYearChange);
    } else if (year.id === "All") {
      const finYear = this.financialYearList;
      finYear.shift();
      this.finYearChange.emit(finYear);
      this.SkuSplitsBasedService._toggleFinYear.emit(this.finYearChange);
    }
  }

  toggleSKU(forecast: string) {
    this.selectedForecast = forecast.toUpperCase();
    localStorage.setItem("forecastType", this.selectedForecast);
     this.forecastType.emit(this.selectedForecast);
    this.SkuSplitsBasedService._toggleSKU.emit(this.selectedForecast);
  }

  editableObjectBuilder(data: any) {  
     const editableArr = [];
    const re = new RegExp("^([0-9]|-)+$");
    const skuData = data.data;
    skuData.forEach((element) => {
      if (re.test(element[this.languages.LABEL_PARTICULARS3])) {
        const currYear = this.getFinYearBasedOnRow(
          element[this.languages.LABEL_PARTICULARS3]
        );
        const currentYrEditableArr = [false, false, false, false];
        if(this.assignedLevel == "Territory Manager" || this.level == "TM"){
          const eMonths = this.getEditableMonthTm(currYear);
          const rowObj = currentYrEditableArr.concat(eMonths);
          editableArr.push(rowObj);
        }else{
          const eMonths = this.getEditableMonth(currYear);
          const rowObj = currentYrEditableArr.concat(eMonths);
          editableArr.push(rowObj);
        }
      } else if (
        this.selectedFinancialYear.name !== "All" &&
        element[this.languages.LABEL_PARTICULARS3] ===
          this.languages.LABEL_VOLUME
      ) {
        const currYear = this.selectedFinancialYear.name;
        const currentYrEditableArr = [false, false, false, false];
        const eMonths = this.getEditableMonth(currYear);
        const rowObj = currentYrEditableArr.concat(eMonths);
        editableArr.push(rowObj);
      } else {
        const rowObj = [];
        for (let i = 0; i <= 16; i++) {
          rowObj.push(false);
        }
        editableArr.push(rowObj);
      }
    });
    this.editableObj = editableArr;
  }

  restructureAndRemoveYearPrefixes(nestedHeaders: (string | { label: string, colspan: number })[]): (string | { label: string, colspan: number })[] {
    // Separate the 'previousYear' elements and other elements
    const previousYearElements: string[] = [];
    const otherElements: (string | { label: string, colspan: number })[] = [];

    nestedHeaders.forEach(item => {
        if (typeof item === 'string' && item.startsWith('previousYear')) {
            previousYearElements.push(item);
        } else {
            otherElements.push(item);
        }
    });

    // Helper function to remove prefixes
    const removeYearPrefix = (item: string) => {
        if (item.startsWith('previousYear')) {
            return item.replace('previousYear', '');
        } else if (item.startsWith('currentYear')) {
            return item.replace('currentYear', '');
        }
        return item;
    };

    // Concatenate 'previousYear' elements at the beginning of the list
    const restructuredHeaders = [otherElements[0], ...previousYearElements, ...otherElements.slice(1)];

    // Remove prefixes from all elements
    return restructuredHeaders.map(item => {
        if (typeof item === 'string') {
            return removeYearPrefix(item);
        }
        return item;
    });
}


   generateTable(data) {  
     if (this.assignedLevel == "Territory Manager" || this.level == "TM") { 
         
       data.data = data.tmdata;
        this.generateTableTm(data);
     }else
   { this.editableObjectBuilder(data);
    let hiddenRows = [];
    let formulas = true;
    if (this.selectProductLength) {
      if (this.selectProductLength.length > 1) {
        hiddenRows = [0];
      }
    }
    if (this.selectedFinancialYear.name === "All") {
      hiddenRows = [0];
    }
    if (
      this.tenantService.getTenant() !== MEXICO ||
      (this.selectProductLength && this.selectProductLength.length < 2)
    ) {
      formulas = true;
    } else {
      formulas = false;
    }
    if (data && !this.isCollapsed) {
      let editableMonths; //earlier code -- const editableMonths = this.getEditableMonth();
      const me = this;
      const currentMonth = getCurrentMonth(this.selectedFinancialYear);
      let currentColumn = null;
      let editableCols = [];
      let columnArray = [];
      let columnheaderArray = [];
       this.hotSettings = {
        data: data.data,
        colHeaders: true,
        licenseKey: "non-commercial-and-evaluation",
        stretchH: "all",
        preventOverflow: "horizontal",
        className: "htCenter",
        formulas: formulas,
        fixedColumnsLeft: 4,
        viewportRowRenderingOffset: 180,
        // renderAllRows: true,
        rowHeights: function (row) {
          return "auto";
        },

        columnSummary: data.columnSummary,
        hiddenRows: {
          copyPasteEnabled: false,
          rows: hiddenRows,
        } as any,

        nestedHeaders: [data.nestedHeaders],
        mergeCells: this.mergeCellsForFy(data),
        fillHandle: false,

        beforeCreateRow: function (row, amount, source) {
          const hot = me.hotRegisterer.getInstance(me.hotID);
          if (row > 1) {
            //updating editable month for every row by passing finyear
            editableCols = [];
            const fetchedMonthObjKey = hot.getDataAtCell(row, 3);
            const editableMonthforCurrentRow =
              this.editableObj[fetchedMonthObjKey];
            editableMonths = editableMonthforCurrentRow;
            editableCols = editableMonths;
            return true;
          }
          return false;
        },
        afterGetColHeader: function (col, TH) {
          function applyClass(elem, className) {
            if (!Handsontable.dom.hasClass(elem, className)) {
              Handsontable.dom.addClass(elem, className);
            }
          }
          if (currentMonth) {
            //commented code to set editable cols in beforecreaterow hook
            // if (editableMonths.includes(TH.children[0].children[0].innerHTML)) {
            //   if (!editableCols.includes(col)) {
            //     editableCols.push(col);
            //   }
            // }
            if (TH.children[0].children[0].innerHTML === currentMonth) {
              currentColumn = col;
              applyClass(TH, "color2");
            } else {
              applyClass(TH, "color1");
            }
          } else {
            if (
              TH.children[0].children[0].innerHTML === me.monthsList[3].month
            ) {
              currentColumn = col;
              applyClass(TH, "color1");
            } else {
              applyClass(TH, "color1");
            }
          }
          columnArray.push({
            col: col,
            name: TH.children[0].children[0].innerHTML,
          });
          columnheaderArray.push({ col: col, name: data.nestedHeaders[col] }); //getcolumnfor basvalueobject
        },
        cells: (row, col) => {
          const hot = me.hotRegisterer.getInstance(me.hotID);
          let column = null;
          columnArray.forEach((element) => {
            if (element.col === col) {
              column = element;
              return;
            }
          });
          let cp: any = {};
          if (col === 16) {
            cp.numericFormat = { pattern: "0,00.0" };
          }
          if (col > 3 && col < 16) {
            cp.numericFormat = { pattern: "0,00.0" };
          }
          if (row !== 1) {
            let data = null;

            data = hot.getDataAtCell(row, col);
            if (data < 0) {
              if (
                (this.tenant === AF || this.tenant === SWAL) &&
                this.selectedForecast != NET &&
                this.isDataEdited
              ) {
                // this.toasterService.errorMessage(this.languages.LABEL_NEGATIVE_VALUES_NOT_ALLOWED);
                this.negativeValueFlag = true;
              }
              hot.setCellMeta(row, col, "valid", false);
            } else {
              hot.setCellMeta(row, col, "valid", true);
            }
          }
          if (col < 4 || row === 1 || col === 15) {
            cp.readOnly = true;
            cp.type = "numeric";
          }
          if (col > 3) {
            cp.allowEmpty = false;
            cp.className = "htCenter volume-cells";
            cp.type = "numeric";
          }
          if (col > 3 && row === 0) {
            cp.className = "bold-td htCenter volume-cells";
            cp.type = "numeric";
          }
          if (row === 1) {
            cp.className = "color3";
            cp.type = "numeric";
          }
          if (col < 4 && row > 1) {
            cp.className = "color5 volume-cells firstChild";
            cp.type = "numeric";
          }
          if (col < 4 && row === 0) {
            cp.className = "color5 firstChild";
          }
          if (col >= currentColumn && row !== 1) {
            cp.className = "htCenter";
          }
          if (currentMonth || this.selectedFinancialYear.name === "All") {
            if (
              col >= currentColumn &&
              row === 0 &&
              this.selectedFinancialYear.name !== "All"
            ) {
              //aggregate row
              cp.className = "htCenter total-cell";
              if (
                col !== hot.countCols() - 1 &&
                (this.selectedForecast !== NET ||
                  this.tenantService.getTenant() === MEXICO)
              ) {
                cp.readOnly = false;
              }
            }
          } else {
            if (col >= currentColumn && row === 0) {
              cp.className = "htCenter total-cell volume-cells";
              cp.readOnly = true;
            }
          }
          if (col < currentColumn) {
            cp.readOnly = true;
          }
          if (currentMonth || this.selectedFinancialYear.name === "All") {
            if (!me.canEditTable && col >= currentColumn && row !== 1) {
              cp.readOnly = true;

              if (row === 0) {
                if (col !== 16) {
                  cp.className =
                    "htCenter volume-cells total-cell noneditablehtInvalid";
                } else {
                  cp.className = "htCenter volume-cells total-cell";
                }
              } else {
                if (col !== 16) {
                  cp.className = "htCenter volume-cells noneditablehtInvalid";
                } else {
                  cp.className = "htCenter volume-cells";
                }
              }
            } else if (
              me.canEditTable &&
              this.editableObj[row][col] &&
              row !== 1 &&
              (col >= currentColumn ||
                this.selectedFinancialYear.name === "All") &&
              (this.selectedForecast !== NET ||
                this.tenantService.getTenant() === MEXICO)
            ) {
              cp.readOnly = false;
              //editable col backgroundcolor white
              if (row !== 0) {
                cp.className = "htCenter editablehtInvalid htInvalid";
                if (
                  column &&
                  this.convertedBaseValues &&
                  this.convertedBaseValues.data &&
                  row > 1 &&
                  col > 3 &&
                  col < 16
                ) {
                  if (this.convertedBaseValues.data.length - 1 >= row - 2) {
                    const cellData = hot.getDataAtCell(row, col);
                    const cellPositiveBaseData =
                      this.convertedBaseValues.data[row - 2][column.name];
                    const cellNegativeBaseData =
                      this.convertedNegativeBaseValues.data[row - 2][
                        column.name
                      ];
                    if (
                      this.isNumber(cellData) &&
                      this.isNumber(cellPositiveBaseData) &&
                      (cellData > cellPositiveBaseData ||
                        cellData < cellNegativeBaseData)
                    ) {
                      cp.className = "htCenter volume-cells htThesholdColor ";
                    }
                  }
                }
              } else {
                if (me.isSameUOM) {
                  cp.className =
                    "htCenter editablehtInvalid htInvalid total-cell";
                } else {
                  cp.className =
                    "htCenter noneditablehtInvalid htInvalid total-cell";
                }
              }
            } else if (
              me.canEditTable &&
              this.editableObj[row][col] &&
              row !== 1 &&
              col >= currentColumn &&
              this.selectedForecast == NET
            ) {
              cp.readOnly = true;
              //editable col backgroundcolor white
              if (row !== 0) {
                cp.className =
                  "htCenter editablehtInvalid htInvalid total-cell";
              } else {
                if (me.isSameUOM) {
                  cp.className =
                    "htCenter editablehtInvalid htInvalid total-cell";
                } else {
                  cp.className =
                    "htCenter noneditablehtInvalid htInvalid total-cell";
                }
              }
            } else if (
              me.canEditTable &&
              !this.editableObj[row][col] &&
              row !== 1 &&
              col >= currentColumn
            ) {
              cp.readOnly = true;
              if (row === 0) {
                cp.className = "htCenter volume-cells total-cell";
              } else {
                cp.className = "htCenter volume-cells";
              }
            } else if (
              (!me.canEditTable &&
                row !== 1 &&
                col > 3 &&
                col < currentColumn) ||
              (me.canEditTable &&
                this.editableObj[row][col] &&
                row !== 1 &&
                col > 3 &&
                col < currentColumn)
            ) {
              cp.readOnly = true;
              if (row !== 0) {
                cp.className = "htCenter noneditablehtInvalid htInvalid";
              } else {
                cp.className =
                  "htCenter noneditablehtInvalid htInvalid total-cell";
              }
            }
          } else {
            if (col >= currentColumn && row !== 1) {
              cp.readOnly = true;
              if (row === 0) {
                cp.className = "htCenter volume-cells total-cell";
              } else {
                cp.className = "htCenter volume-cells";
              }
            }
          }
          if (me.isSameUOM) {
            if (
              me.canEditTable &&
              row === 0 &&
              (col >= currentColumn ||
                this.selectedFinancialYear.name === "All") &&
              col !== 16
            ) {
              if (this.selectedMaterialGroups) {
                if (
                  this.selectedMaterialGroups.length <= 1 &&
                  this.editableObj[row][col] &&
                  (this.selectedForecast !== NET ||
                    this.tenantService.getTenant() === MEXICO)
                ) {
                  cp.className =
                    "htCenter volume-cells editablehtInvalid total-cell";
                  cp.readOnly = false;
                } else {
                  cp.className = "htCenter noneditablehtInvalid total-cell";
                  cp.readOnly = true;
                }
              } else {
                if (
                  this.editableObj[row][col] &&
                  (this.selectedForecast !== NET ||
                    this.tenantService.getTenant() === MEXICO)
                ) {
                  cp.className =
                    "htCenter volume-cells editablehtInvalid total-cell";
                  cp.readOnly = false;
                } else {
                  cp.className = "htCenter noneditablehtInvalid total-cell";
                  cp.readOnly = true;
                }
              }
            } else if (!me.canEditTable && row === 0 && col >= currentColumn) {
              if (col !== 15) {
                cp.className = "htCenter noneditablehtInvalid total-cell";
                cp.readOnly = true;
              } else {
                cp.className = "htCenter total-cell";
                cp.readOnly = true;
              }
            }
          } else {
            if (row === 0 && col > 3 && col !== 16) {
              cp.className = "htCenter noneditablehtInvalid total-cell";
              cp.readOnly = true;
            }
          }
            // Highlight the last edited cell
         let pageNumber =  +localStorage.getItem("page");   
         const lastCell = JSON.parse(localStorage.getItem("lastCell") || "[]"); 
           if (lastCell.length > 0 && lastCell[0] === row && lastCell[1] === col && lastCell[2] === pageNumber) {
           cp.className =  'highlighted-cell'; 
         }
          cp.forceNumeric = true;
          return cp;
        },
        afterBeginEditing:(row, col) => { 
          let pageNum =  +localStorage.getItem("page"); 
           const selectedCell = [row, col,pageNum]; 
           localStorage.setItem("lastCell", JSON.stringify(selectedCell));
         },
        afterSelection: function (
          r,
          c,
          r2,
          c2,
          preventScrolling,
          selectionLayerLevel
        ) {
          preventScrolling.value = true;
        },
        afterOnCellMouseDown: (event, coordinates) => {
          this.setInputMaxLength();
        },
        beforeKeyDown: (event) => {
          this.setInputMaxLength();
        },
        afterChange: (changes, src) => {
          //creating list of sku edited

          const hot = me.hotRegisterer.getInstance(me.hotID);
          let column = null;
          let totalColumns = [];
          // Set 0 as default value if entered value is null, empty or string
          if (changes && changes.length > 0 && this.tenant === MEXICO) {
            for (let change of changes) {
              if (
                (changes && change[3] === null) ||
                (changes && change[3] === "") ||
                (changes && change[3] === undefined) ||
                (changes &&
                  typeof change[3] === "string" &&
                  (src === "edit" || src === "CopyPaste.paste"))
              ) {
                column = this.getColumn(columnArray, change[1]);
                if (
                  change[2] !== null &&
                  this.isNumber(Number(change[2])) &&
                  change[2] !== "" &&
                  change[2] !== undefined
                ) {
                  me.data.data[change[0]][column.name] = change[2];
                } else {
                  me.data.data[change[0]][column.name] = 0;
                }

                this.isDataEdited = true;
                this.dataEdited.emit(this.isDataEdited);
              }
            }
          }
          if (
            changes &&
            changes.length > 0 &&
            (this.tenant === SWAL || this.tenant === AF) &&
            this.selectedForecast != NET
          ) {
            for (let change of changes) {
              if (
                (changes && change[3] === null) ||
                (changes && change[3] === "") ||
                (changes && change[3] === undefined) ||
                (changes && change[3]! < 0) ||
                (changes &&
                  typeof change[3] === "string" &&
                  (src === "edit" || src === "CopyPaste.paste"))
              ) {
                column = this.getColumn(columnArray, change[1]);
                if (
                  change[2] !== null &&
                  this.isNumber(Number(change[2])) &&
                  change[2] !== "" &&
                  change[2] !== undefined
                ) {
                  me.data.data[change[0]][column.name] = change[2];
                } else {
                  me.data.data[change[0]][column.name] = 0;
                }

                if (change[3] >= 0) {
                  this.isDataEdited = true;
                  this.dataEdited.emit(this.isDataEdited);
                }
              }
            }
          }

          if (
            changes &&
            changes.length > 0 &&
            (src === "edit" || src === "CopyPaste.paste") &&
            (this.tenant === MEXICO ||
              ((this.tenant === SWAL || this.tenant === AF) &&
                this.selectedForecast != NET))
          ) {
            for (let change of changes) {
              if (
                changes &&
                change[3] !== null &&
                changes &&
                change[3] !== "" &&
                changes &&
                change[3] !== undefined &&
                changes &&
                typeof change[3] !== "string" &&
                (src === "edit" || src === "CopyPaste.paste")
              ) {
                column = this.getColumn(columnArray, change[1]);
                if (change[3] >= 0 || this.tenant === MEXICO) {
                  //mexico allowed negative value on edit
                  this.isDataEdited = true;
                  this.dataEdited.emit(this.isDataEdited);
                }

                // Calculate Weightage and split based on ratio to all SKU
                if (change[0] === 0) {
                  if (
                    (this.tenant === SWAL || this.tenant === AF) &&
                    this.selectedForecast != NET &&
                    change[3] < 0
                  )
                    change[3] = change[2];
                  let columnCount: number = hot.countRows() - 2;
                  let weightage = [];
                  for (let j = 2; j < hot.countRows(); j++) {
                    let weight = null;
                    let cellData = me.data.data[j][column.name];
                    if (this.isNumber(change[2])) {
                      if (isNaN(cellData / change[2])) {
                        weight = 100 / columnCount;
                      } else {
                        weight = (cellData / change[2]) * 100;
                      }
                    } else if (isNaN(change[2])) {
                      weight = 100 / columnCount;
                    } else {
                      weight = 100 / columnCount;
                    }
                    weightage[j] = weight;
                    let splitValue = Number(
                      (weightage[j] / 100) * change[3]
                    ).toFixed(1);
                    me.data.data[j][column.name] = splitValue;
                    const arr = [
                      me.data.data[j][this.languages.LABEL_PARTICULARS],
                      me.data.data[j][this.languages.LABEL_PARTICULARS3],
                    ];
                    if (!this.editedSkuList.includes(arr.toString())) {
                       this.editedSkuList.push(arr.toString());
                      this.editedSkuCodes.emit(this.editedSkuList);
                    }
                  }
                } else {
                  if (
                    (this.tenant === SWAL || this.tenant === AF) &&
                    this.selectedForecast != NET &&
                    change[3] < 0
                  ) {
                    this.negativeValueFlag = true;
                  }
                }
                let total: number = null;
                let netTotal: number = null;
                let volumeTotal: number = null;
                if (this.tenantService.getTenant() !== MEXICO) {
                  let grossPrice = null;
                  let cellData = hot.getDataAtCell(change[0], 0);
                  this.skuPriceMaster.forEach((ele) => {
                    if (ele.skuCode === cellData) {
                      grossPrice = ele.grossPrice;
                    }
                  });
                  if (column) {
                    const updatedTotal =
                      (Number(me.data.data[change[0]][column.name]) -
                        Number(change[2])) *
                      grossPrice;
                    const updatedVolumTotal =
                      Number(me.data.data[change[0]][column.name]) -
                      Number(change[2]);
                    total = Number(total) + updatedTotal;
                    volumeTotal = Number(volumeTotal) + updatedVolumTotal;
                  }
                  let year;
                  year =
                    me.data.data[change[0]][this.languages.LABEL_PARTICULARS3]; //financial year of editaed cell
                  totalColumns.push({
                    total: total,
                    column: column,
                    volume: volumeTotal,
                    financialYear: year,
                  }); //pass year to update for particular year
                } else if (
                  this.selectProductLength &&
                  this.selectProductLength.length < 2
                ) {
                  for (let change of changes) {
                    if (
                      changes &&
                      change[3] !== null &&
                      changes &&
                      change[3] !== "" &&
                      changes &&
                      change[3] !== undefined &&
                      changes &&
                      typeof change[3] !== "string" &&
                      (src === "edit" || src === "CopyPaste.paste")
                    ) {
                      column = this.getColumn(columnArray, change[1]);
                      let total: number = null;
                      let netTotal: number = null;
                      let volumeTotal: number = null;
                      let grossPrice = null;
                      let netPrice = null;
                      let cellData = hot.getDataAtCell(change[0], 0);
                      if (this.selectedAreas.length > 0) {
                        exit_loop: for (let skuprice of this.skuPriceMaster) {
                          if (skuprice.skuCode === cellData) {
                            skuprice.grossPrice.forEach((price) => {
                              if (this.selectedAreas.includes(price.geoId)) {
                                grossPrice = price.grossPrice;
                                if (
                                  (this.userLevel && this.userLevel === PC) ||
                                  this.getLevel() === PC
                                ) {
                                  netPrice = price.netPrice;
                                }
                              }
                            });
                            break exit_loop;
                          }
                        }
                        if (column) {
                          const updatedTotal =
                            (Number(this.data.data[change[0]][column.name]) -
                              Number(change[2])) *
                            grossPrice;
                          total = Number(total) + updatedTotal;
                        }
                        if (
                          (this.userLevel && this.userLevel === PC) ||
                          this.getLevel() === PC
                        ) {
                          const updatedNetTotal =
                            (Number(this.data.data[change[0]][column.name]) -
                              Number(change[2])) *
                            netPrice;
                          if (column) {
                            netTotal = Number(netTotal) + updatedNetTotal;
                          }
                        }
                      } else {
                        exit_loop: for (
                          let p = 0;
                          p < this.skuPriceMaster.length;
                          p++
                        ) {
                          if (this.skuPriceMaster[p].skuCode === cellData) {
                            this.skuPriceMaster[p].grossPrice.forEach(
                              (price) => {
                                grossPrice = price.grossPrice;
                                if (
                                  (this.userLevel && this.userLevel === PC) ||
                                  this.getLevel() === PC
                                ) {
                                  netPrice = price.netPrice;
                                }
                              }
                            );
                            break exit_loop;
                          }
                        }
                        if (column) {
                          const updatedTotal =
                            (Number(this.data.data[change[0]][column.name]) -
                              Number(change[2])) *
                            grossPrice;
                          total = Number(total) + updatedTotal;
                        }
                        if (
                          (this.userLevel && this.userLevel === PC) ||
                          this.getLevel() === PC
                        ) {
                          const updatedNetTotal =
                            (Number(this.data.data[change[0]][column.name]) -
                              Number(change[2])) *
                            netPrice;
                          if (column) {
                            netTotal = Number(netTotal) + updatedNetTotal;
                          }
                        }
                      }
                      if (column) {
                        const updatedVolumTotal =
                          Number(me.data.data[change[0]][column.name]) -
                          Number(change[2]);
                        volumeTotal = Number(volumeTotal) + updatedVolumTotal;
                      }
                      let year;
                      year =
                        me.data.data[change[0]][
                          this.languages.LABEL_PARTICULARS3
                        ]; //financial year of editaed cell
                      totalColumns.push({
                        total: total,
                        netTotal: netTotal,
                        column: column,
                        volume: volumeTotal,
                        financialYear: year,
                      }); //pass year to update for particular year
                    }
                  }
                }
              }
            }
            if (
              this.tenantService.getTenant() !== MEXICO ||
              (this.selectProductLength && this.selectProductLength.length < 2)
            ) {
              // me.changeOtherRevenue(totalColumns);
            }
          }
          if (
            this.tenantService.getTenant() === MEXICO &&
            this.selectProductLength &&
            this.selectProductLength.length > 1
          ) {
            me.calculateTotal(changes, src, columnArray);
          }
          if (src === "edit") {
            const arr = [
              this.data.data[changes[0][0]][this.languages.LABEL_PARTICULARS],
              this.data.data[changes[0][0]][this.languages.LABEL_PARTICULARS3],
            ];
            if (!this.editedSkuList.includes(arr.toString())) {
              this.editedSkuList.push(arr.toString());
               this.editedSkuCodes.emit(this.editedSkuList);
            }
          }
        },
        afterRender: () => { 
          setTimeout(()=>{
            const element = document.querySelector('td.highlighted-cell')
            if(element){
              element.scrollIntoView({behavior: "auto", block: "center"}) 
            }
           },100)
        }
      };

      const hot = me.hotRegisterer.getInstance(me.hotID);
      if (hot) {
        hot.render();
      }
    }}
  }

  mergeCellsForFy(data: any) {
      const dataValue = data.data;
    const mergingAmount = this.financialYearList.length - 1;
    const mergeCellInfo = [{ row: 0, col: 0, rowspan: 1, colspan: 4 }];
    let i = 0;
    while (i < dataValue.length) {
      if (mergingAmount === 2) {
        let val1 = null;
        let val2 = null;
        try {
          val1 = dataValue[i][this.languages.LABEL_PARTICULARS];
          val2 = dataValue[i + 1][this.languages.LABEL_PARTICULARS];
          if (val1 && val2) {
            if (val1 === val2) {
              mergeCellInfo.push({
                row: i,
                col: 0,
                rowspan: mergingAmount,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 1,
                rowspan: mergingAmount,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 2,
                rowspan: mergingAmount,
                colspan: 1,
              });
            }
          }
        } catch (error) {
          val1 = dataValue[i][this.languages.LABEL_PARTICULARS];
          val2 = null;
        }
      } else if (mergingAmount === 3) {
        let val1 = null;
        let val2 = null;
        let val3 = null;
        try {
          val1 = dataValue[i][this.languages.LABEL_PARTICULARS];
          val2 = dataValue[i + 1]
            ? dataValue[i + 1][this.languages.LABEL_PARTICULARS]
            : "";
          val3 = dataValue[i + 2]
            ? dataValue[i + 2][this.languages.LABEL_PARTICULARS]
            : "";
          if (val1 && val2 && val3) {
            if (val1 === val2 && val2 === val3) {
              mergeCellInfo.push({
                row: i,
                col: 0,
                rowspan: mergingAmount,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 1,
                rowspan: mergingAmount,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 2,
                rowspan: mergingAmount,
                colspan: 1,
              });
            } else if (val1 === val2 && val2 !== val3) {
              mergeCellInfo.push({
                row: i,
                col: 0,
                rowspan: mergingAmount - 1,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 1,
                rowspan: mergingAmount - 1,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 2,
                rowspan: mergingAmount - 1,
                colspan: 1,
              });
            }
          }
        } catch (error) {
          val1 = dataValue[i][this.languages.LABEL_PARTICULARS];
          val2 = dataValue[i + 1]
            ? dataValue[i + 1][this.languages.LABEL_PARTICULARS]
            : "";
          val3 = null;
          if (val1 === val2) {
            mergeCellInfo.push({
              row: i,
              col: 0,
              rowspan: mergingAmount - 1,
              colspan: 1,
            });
            mergeCellInfo.push({
              row: i,
              col: 1,
              rowspan: mergingAmount - 1,
              colspan: 1,
            });
            mergeCellInfo.push({
              row: i,
              col: 2,
              rowspan: mergingAmount - 1,
              colspan: 1,
            });
          }
        }
      } else if (mergingAmount === 4) {
        let val1 = null;
        let val2 = null;
        let val3 = null;
        let val4 = null;
        try {
          val1 = dataValue[i][this.languages.LABEL_PARTICULARS];
          val2 = dataValue[i + 1]
            ? dataValue[i + 1][this.languages.LABEL_PARTICULARS]
            : "";
          val3 = dataValue[i + 2]
            ? dataValue[i + 2][this.languages.LABEL_PARTICULARS]
            : "";
          val4 = dataValue[i + 2]
            ? dataValue[i + 3][this.languages.LABEL_PARTICULARS]
            : "";
          if (val1 && val2 && val3 && val4) {
            if (val1 === val2 && val2 === val3 && val3 === val4) {
              mergeCellInfo.push({
                row: i,
                col: 0,
                rowspan: mergingAmount,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 1,
                rowspan: mergingAmount,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 2,
                rowspan: mergingAmount,
                colspan: 1,
              });
            } else if (val1 === val2 && val2 === val3) {
              mergeCellInfo.push({
                row: i,
                col: 0,
                rowspan: mergingAmount - 1,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 1,
                rowspan: mergingAmount - 1,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 2,
                rowspan: mergingAmount - 1,
                colspan: 1,
              });
            } else if (val1 === val2) {
              mergeCellInfo.push({
                row: i,
                col: 0,
                rowspan: mergingAmount - 2,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 1,
                rowspan: mergingAmount - 2,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 2,
                rowspan: mergingAmount - 2,
                colspan: 1,
              });
            }
          }
        } catch (error) {
          val1 = dataValue[i][this.languages.LABEL_PARTICULARS];
          val2 = dataValue[i + 1]
            ? dataValue[i + 1][this.languages.LABEL_PARTICULARS]
            : "";
          val3 = null;
          val4 = null;
          if (val1 === val2) {
            mergeCellInfo.push({
              row: i,
              col: 0,
              rowspan: mergingAmount - 2,
              colspan: 1,
            });
            mergeCellInfo.push({
              row: i,
              col: 1,
              rowspan: mergingAmount - 2,
              colspan: 1,
            });
            mergeCellInfo.push({
              row: i,
              col: 2,
              rowspan: mergingAmount - 2,
              colspan: 1,
            });
          }
        }
      }
      if (dataValue[i][this.languages.LABEL_PARTICULARS] === "Sales Quantity") {
        mergeCellInfo.push({ row: i, col: 0, rowspan: 1, colspan: 4 });
      }
      // if (dataValue[i][this.languages.LABEL_PARTICULARS] === "Last Year Volume") {
      //   mergeCellInfo.push({ row: i, col: 0, rowspan: 1, colspan: 4 });
      // }
      if (
        dataValue[i][this.languages.LABEL_PARTICULARS] === "Budget Quantity"
      ) {
        mergeCellInfo.push({ row: i, col: 0, rowspan: 1, colspan: 4 });
      }
      if (
        dataValue[i][this.languages.LABEL_PARTICULARS] === "Predictive Forecast"
      ) {
        mergeCellInfo.push({ row: i, col: 0, rowspan: 1, colspan: 4 });
      }
      i = i + 1;
    }
    return mergeCellInfo;
  }
  
  getEditableMonth(finyear) {
    const dt = new Date();
    var yr = finyear.toString();
    var currDecade = dt.getFullYear().toString().substring(0, 2);
    currDecade.concat(yr);
    const futureDate = dt.setMonth(dt.getMonth() + 18);
    let data = [];
    let editableMonths = [];
    const d = new Date();
    const currentYr = d.getFullYear();
    const yearValue = yr.split("-");
    let fiscalArray = [];
    // if (yearValue[0] < currentYr) {
    //condition where the incoming year is less than current year .. no cells editable for such row
    // for (let i = 0; i <= 11; i++) {
    // fiscalArray.push(false);
    // }
    // fiscalArray.push(false);
    // } else {
    var offset = 3;
    for (let i = 1; i <= 12; i++) {
      var monthIndex = i + offset;
      if (i <= 3) {
        data.push(
          "20" + yearValue[1] + "/" + this.monthsList[i - 1].id + "/" + 1
        );
      } else {
        data.push(yearValue[0] + "/" + this.monthsList[i - 1].id + "/" + 1);
      }
    }
    data.forEach((ele) => {
      if (
        new Date(ele).setHours(0, 0, 0, 0) <
        new Date(futureDate).setHours(0, 0, 0, 0)
      ) {
        editableMonths.push(this.monthsList[ele.split("/")[1] - 1].month);
      }
    });
    //setting fiscal array with months in fiscal order
    for (i = 0; i < this.monthsList.length; i++) {
      var monthIndex = i + offset;
      fiscalArray.push(
        this.monthsList[monthIndex % this.monthsList.length].month
      );
    }
    const currMonthForYear = getCurrentMonth({ name: finyear }); //finding the current month
    const currentMonthIndex = fiscalArray.indexOf(currMonthForYear, 0); //finding position of the current month in fiscalarray

    //foreach loop to set check editablemonths array and set true where they match in fiscalarray
    editableMonths.forEach((editableres) => {
      fiscalArray.forEach((fiscalres, index) => {
        if (editableres === fiscalres) {
          fiscalArray[index] = true;
        }
      });
    });
    //month less than current month is not editable hence setting them as false
    for (var i = 0; i < editableMonths.length; i++) {
      if (i < currentMonthIndex) {
        fiscalArray[i] = false;
      }
    }

    //incase few elements of fiscalarray are left as string we need to set them as false because they were not present in editablemonth list
    for (var i = 0; i < fiscalArray.length; i++) {
      if (
        typeof fiscalArray[i] === "string" ||
        fiscalArray[i] instanceof String
      ) {
        fiscalArray[i] = false;
      }
    }
    fiscalArray.push(false); // total column is uneditable
    // }
    return fiscalArray;
  }

  getEditableMonthTm(finyear) {
    const dt = new Date();
    var yr = finyear.toString();
    var currDecade = dt.getFullYear().toString().substring(0, 2);
    currDecade.concat(yr);
    const futureDate = dt.setMonth(dt.getMonth() + 18);
    let data = [];
    let editableMonths = [];
    const d = new Date();
    const currentYr = d.getFullYear();
    const yearValue = yr.split("-");
    let fiscalArray = [];
    // if (yearValue[0] < currentYr) {
    //condition where the incoming year is less than current year .. no cells editable for such row
    // for (let i = 0; i <= 11; i++) {
    // fiscalArray.push(false);
    // }
    // fiscalArray.push(false);
    // } else {
    var offset = 3;
    for (let i = 1; i <= 12; i++) {
      var monthIndex = i + offset;
      if (i <= 5) {
        data.push(
          "20" + yearValue[1] + "/" + this.monthsList[i - 1].id + "/" + 1
        );
      } else {
        data.push(yearValue[0] + "/" + this.monthsList[i - 1].id + "/" + 1);
      }
    }
    data.forEach((ele) => {
      if (
        new Date(ele).setHours(0, 0, 0, 0) <
        new Date(futureDate).setHours(0, 0, 0, 0)
      ) {
        editableMonths.push(this.monthsList[ele.split("/")[1] - 1].month);
      }
    });
    //setting fiscal array with months in fiscal order
    for (i = 0; i < this.monthsList.length; i++) {
      var monthIndex = i + offset;
      fiscalArray.push(
        this.monthsList[monthIndex % this.monthsList.length].month
      );
    }
    const currMonthForYear = getCurrentMonth({ name: finyear }); //finding the current month
    const currentMonthIndex = fiscalArray.indexOf(currMonthForYear, 0); //finding position of the current month in fiscalarray

    //foreach loop to set check editablemonths array and set true where they match in fiscalarray
    editableMonths.forEach((editableres) => {
      fiscalArray.forEach((fiscalres, index) => {
        if (editableres === fiscalres) {
          fiscalArray[index] = true;
        }
      });
    });
    //month less than current month is not editable hence setting them as false
    for (var i = 0; i < editableMonths.length; i++) {
      if (i < currentMonthIndex) {
        fiscalArray[i] = false;
      }
    }

    //incase few elements of fiscalarray are left as string we need to set them as false because they were not present in editablemonth list
    for (var i = 0; i < fiscalArray.length; i++) {
      if (
        typeof fiscalArray[i] === "string" ||
        fiscalArray[i] instanceof String
      ) {
        fiscalArray[i] = false;
      }
    }
    fiscalArray.push(false); // total column is uneditable
    // }
    return fiscalArray;
  }

  getFinYearBasedOnRow(finYear) {
    //getting fin year from table and returning editable month for that year
    let dt = new Date();
    const yrVal = finYear.toString();
    const decade = "20";
    const cCatYear = decade.concat(yrVal);
    return cCatYear;
  }

  changeRevenue(changes, src, columnArray) {
    if (typeof Worker !== "undefined") {
      const worker = new Worker("./common-sku-splits-based.worker", {});
      worker.onmessage = ({ data }) => {
        localStorage.setItem("isRevenueCalculation", "no");
        const object1 = { totalColumns: data };
        this.updateRevenue.emit(object1);
      };
      let hot = this.hotRegisterer.getInstance(this.hotID);
      const params = {
        changes,
        src,
        columnArray,
        count: hot.countRows(),
        selectedAreas: this.selectedAreas,
        data: this.data,
        skuPriceMaster: this.skuPriceMaster,
        userLevel: this.userLevel,
        currentLevel: this.getLevel(),
      };
      worker.postMessage(params);
    }
  }

  calculateTotal(changes, src, columnArray) {
    let hot = this.hotRegisterer.getInstance(this.hotID);
    let index = this.selectProductLength.length < 2 ? 0 : 2;
    for (let i = index; i < hot.countRows(); i++) {
      if (i !== 1) {
        let total = null;
        for (const [key, value] of Object.entries(this.data.data[i])) {
          if (
            key !== this.languages.LABEL_PARTICULARS &&
            key !== this.languages.LABEL_PARTICULARS3 &&
            key !== this.languages.LABEL_PARTICULARS1 &&
            key !== this.languages.LABEL_PARTICULARS2 &&
            key !== this.languages.LABEL_TOTAL
          ) {
            total = total + Number(value);
          }
        }
        this.data.data[i][this.languages.LABEL_TOTAL] = total;
      }
    }
    hot.render();
    if (
      changes &&
      changes.length > 0 &&
      (src === "edit" || src === "CopyPaste.paste")
    ) {
      localStorage.setItem("isRevenueCalculation", "yes");
      setTimeout(() => {
        this.changeRevenue(changes, src, columnArray);
      }, 5000);
    }
  }

  changeOtherRevenue(totalColumns) {
    this.hotSettings = null;
    this.chRef.detectChanges();
    this.generateTable(this.data);  
     const lastCell = JSON.parse(localStorage.getItem("lastCell"));
    setTimeout(() => {
      const newHot = this.hotRegisterer.getInstance(this.hotID);
      if (lastCell && lastCell.length > 0) {
        newHot.selectCell(lastCell[0], lastCell[1], lastCell[2], lastCell[3]);
      }
      document.getElementById("splitContainerSetion").scrollIntoView();
    }, 0.1); 
    const object1 = {
      totalColumns: totalColumns,
      forecastType: this.selectedForecast,
    };
    this.updateRevenue.emit(object1);
  }

  getLevel() {
    if (this.userLevel) {
      return this.userLevel;
    } else {
      return this.profile.level
        .match(/\b(\w)/g)
        .join("")
        .toUpperCase();
    }
  }

  isNumber(n) {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
  }

  setInputMaxLength() {
    let textarea = document.getElementsByClassName("handsontableInput");
    if (textarea && textarea[0]) {
      (<any>textarea[0]).setAttribute("maxlength", 18);
    }
  }

  getColumn(columnArray, changes) {
    let column = null;
    exit_loop: for (let columnData of columnArray) {
      if (columnData.name === changes) {
        column = columnData;
        break exit_loop;
      }
    }
    return column;
  }

  collapseTableView(collapse: NgbCollapse) {
    if (this.closeSkuDialog == true) {
    } else {
      collapse.collapsed = !collapse.collapsed;
      this.isCollapsed = collapse.collapsed;
      if (!collapse.collapsed) {
        setTimeout(() => {
          this.generateTable(this.data);
        }, 0);
      }
    }
  }
 
  lastEditCell:{row,col};
  generateTableTm(data:any) {
      this.editableObjectBuilder(data);
  let hiddenRows = [];
  let formulas = true;
   if (this.selectProductLength) {
    if (this.selectProductLength.length > 1) {
      hiddenRows = [0];
    }
  }
  if (this.selectedFinancialYear.name === "All") {
    hiddenRows = [0];
  }
  if (
    this.tenantService.getTenant() !== MEXICO ||
    (this.selectProductLength && this.selectProductLength.length < 2)
  ) {
    formulas = true;
  } else {
    formulas = false;
  }
  if (data && !this.isCollapsed) {  
    let editableMonths;  
    const me = this;
    const currentMonth = getCurrentMonth(this.selectedFinancialYear);
  
    let currentColumn = null;
    let editableCols = [];
    let columnArray = [];
    let columnheaderArray = [];
       // Arrays to store reordered headers
       const previousYearHeaders = [];
       const currentYearHeaders = [];
       const otherHeaders = [];
  
          function reorderAndSpliceNestedHeaders(nestedHeaders) {
            // Extract the first element assuming it's the "PARTICULARS" header
            const particularsHeader = nestedHeaders.shift();   
            // Extract the last element assuming it's a total or similar header, if present
            const totalHeader = (nestedHeaders[nestedHeaders.length - 1] === " ") ? nestedHeaders.pop() : null; 
            nestedHeaders.forEach(header => {
                if (typeof header === 'string' && header.includes("previousYear")) {
                    previousYearHeaders.push(header.replace("previousYear", ""));
                } else if (typeof header === 'string' && header.includes("currentYear")) {
                    currentYearHeaders.push(header.replace("currentYear", ""));
                } else {
                    otherHeaders.push(header.replace("DUMMY", ""));
                }
            });
            
            // Combine all parts back into the reordered headers
            const reorderedHeaders = [particularsHeader,...previousYearHeaders, ...otherHeaders, ...currentYearHeaders];
            
            // Add the total header back if it was popped earlier
            if (totalHeader) {
                reorderedHeaders.push(totalHeader);
            }
        
            return reorderedHeaders;
        }
        this.SkuSplitsBasedService.setTmFiscalMonth(currentYearHeaders)
          // Reorder and splice the nestedHeaders from data
          const reorderedAndSplicedHeaders = reorderAndSpliceNestedHeaders(data.nestedHeaders);
 
     this.hotSettings = {
      data: data.data,
      colHeaders: true,
      licenseKey: "non-commercial-and-evaluation",
      stretchH: "all",
      preventOverflow: "horizontal",
      className: "htCenter",
      formulas: formulas,
      fixedColumnsLeft: 4,
      viewportRowRenderingOffset: 180,
      // renderAllRows: true,
      rowHeights: function (row) {
        return "auto";
      },
  
      columnSummary: data.columnSummary,
      hiddenRows: {
        copyPasteEnabled: false,
        rows: hiddenRows,
      } as any,
  
      nestedHeaders: [reorderedAndSplicedHeaders],
      mergeCells: this.mergeCellsForFyTm(data),
      fillHandle: false,
  
      beforeCreateRow: function (row, amount, source) {  
        const hot = me.hotRegisterer.getInstance(me.hotID);
        if (row > 1) {
          //updating editable month for every row by passing finyear
          editableCols = [];
          const fetchedMonthObjKey = hot.getDataAtCell(row, 5);
          const editableMonthforCurrentRow =
            this.editableObj[fetchedMonthObjKey];
          editableMonths = editableMonthforCurrentRow;
          editableCols = editableMonths;
          return true;
        }
        return false;
      },
      afterGetColHeader: function (col, TH) {
        function applyClass(elem, className) {
          if (!Handsontable.dom.hasClass(elem, className)) {
            Handsontable.dom.addClass(elem, className);
          }
        }
        if (currentMonth) {   
          if (TH.children[0].children[0].innerHTML === currentMonth) {
            currentColumn = col;
            applyClass(TH, "color2");
          } else {
            applyClass(TH, "color1");
          }
        } else {
          if (
            TH.children[0].children[0].innerHTML === me.monthsList[3].month
          ) {
            currentColumn = col;
            applyClass(TH, "color1 ");
          } else {
            applyClass(TH, "color1");
          }
        }
        columnArray.push({
          col: col,
          name: TH.children[0].children[0].innerHTML,
        });
        columnheaderArray.push({ col: col, name: data.nestedHeaders[col] }); //getcolumnfor basvalueobject
      },
      cells: (row, col) => {    
           const hot = me.hotRegisterer.getInstance(me.hotID);
        let column = null;
        columnArray.forEach((element) => {
          if (element.col === col) {
            column = element;
            return;
          }
        });
        let cp: any = {};
      
        if (col === 16) {
          cp.numericFormat = { pattern: "0,00.0" };
        }
        if (col > 3 && col < 16) {
          cp.numericFormat = { pattern: "0,00.0" };
        }
        if (row !== 1) {
          let data = null;
  
          data = hot.getDataAtCell(row, col);
          if (data < 0) { 
            if (
              (this.tenant === AF || this.tenant === SWAL) &&
              this.selectedForecast != NET &&
              this.isDataEdited
            ) {
              // this.toasterService.errorMessage(this.languages.LABEL_NEGATIVE_VALUES_NOT_ALLOWED);
              this.negativeValueFlag = true;
            }
            hot.setCellMeta(row, col, "valid", false);
          } else {
            hot.setCellMeta(row, col, "valid", true);
          }
        }
        if (col < 3 || row === 1 || col === 15) {
          cp.readOnly = true;
          cp.type = "numeric";
        }
        if (col > 3) {
          cp.allowEmpty = false;
          cp.className = "htCenter volume-cells";
          cp.type = "numeric";
        }
        if (col > 3 && row === 0) {  
          cp.className = "bold-td htCenter volume-cells";
          cp.type = "numeric";
        }
        if (row === 1) { 
          cp.className = "color3";
          cp.type = "numeric";
        }
        if (col < 1 && row === 2) {
            cp.className = "color3"; 
        }
        if (col < 4 && row == 2) {
          cp.className = "color3";
          cp.type = "numeric";
        }
        if (col < 4 && row > 2) {
          cp.className = "color5 volume-cells firstChild";
          cp.type = "numeric";
        }
     
        if (col < 4 && row === 1) {
          cp.className = "color3";
        }
        if (col >= currentColumn && row !== 1) {
          cp.className = "htCenter";
        }
        if (currentMonth || this.selectedFinancialYear.name === "All") {
          if (
            col >= currentColumn &&
            row === 0 &&
            this.selectedFinancialYear.name !== "All"
          ) {
            //aggregate row
            cp.className = "htCenter total-cell";
            if (
              col !== hot.countCols() - 1 &&
              (this.selectedForecast !== NET ||
                this.tenantService.getTenant() === MEXICO)
            ) {
              cp.readOnly = false;
            }
          }
        } else {
          if (col >= currentColumn && row === 0) { 
            cp.className = "htCenter total-cell volume-cells";
            cp.readOnly = true;
          }
        }
        if (col < currentColumn) {
          cp.readOnly = true;
        }
        if (currentMonth || this.selectedFinancialYear.name === "All") { 
          if (!me.canEditTable && col >= currentColumn && row !== 1) {
            cp.readOnly = true;
  
            if (row === 0) {  
              if (col !== 16) {
                cp.className =
                  "htCenter volume-cells total-cell noneditablehtInvalid";
              } else {
                cp.className = "htCenter volume-cells total-cell";
              }
            } else {
              if (col !== 16) {
                cp.className = "htCenter volume-cells noneditablehtInvalid";
              } else {
                cp.className = "htCenter volume-cells";
              }
            }
          } else if (
            me.canEditTable &&
            this.editableObj[row][col] &&
            row !== 1 &&
            (col >= currentColumn ||
              this.selectedFinancialYear.name === "All") &&
            (this.selectedForecast !== NET ||
              this.tenantService.getTenant() === MEXICO)
          ) {  
            cp.readOnly = false;
            //editable col backgroundcolor white
            if (row !== 0) { 
              cp.className = "htCenter editablehtInvalid htInvalid";
              if (
                column &&
                this.convertedBaseValues &&
                this.convertedBaseValues.data &&
                row > 1 &&
                col > 3 &&
                col < 16
              ) {
                if (this.convertedBaseValues.data.length - 1 >= row - 2) {
                  const cellData = hot.getDataAtCell(row, col);
                  const cellPositiveBaseData =
                    this.convertedBaseValues.data[row - 2][column.name];
                  const cellNegativeBaseData =
                    this.convertedNegativeBaseValues.data[row - 2][
                      column.name
                    ];
                  if (
                    this.isNumber(cellData) &&
                    this.isNumber(cellPositiveBaseData) &&
                    (cellData > cellPositiveBaseData ||
                      cellData < cellNegativeBaseData)
                  ) {
                    cp.className = "htCenter volume-cells htThesholdColor ";
                  }
                }
              }
            } else {
              if (me.isSameUOM) {
                cp.className =
                  "htCenter editablehtInvalid htInvalid total-cell";
              } else {
                cp.className =
                  "htCenter noneditablehtInvalid htInvalid total-cell";
              }
            }
          } else if (
            me.canEditTable &&
            this.editableObj[row][col] &&
            row !== 1 &&
            col >= currentColumn &&
            this.selectedForecast == NET
          ) {
            cp.readOnly = true;
            //editable col backgroundcolor white
            if (row !== 0) {
              cp.className =
                "htCenter editablehtInvalid htInvalid total-cell";
            } else {
              if (me.isSameUOM) {
                cp.className =
                  "htCenter editablehtInvalid htInvalid total-cell";
              } else {
                cp.className =
                  "htCenter noneditablehtInvalid htInvalid total-cell";
              }
            }
          } else if (
            me.canEditTable &&
            !this.editableObj[row][col] &&
            row !== 1 &&
            col >= currentColumn
          ) {
            cp.readOnly = true;
            if (row === 0) {
              cp.className = "htCenter volume-cells total-cell";
            } else {
              cp.className = "htCenter volume-cells";
            }
          } else if (
            (!me.canEditTable &&
              row !== 1 &&
              col > 3 &&
              col < currentColumn) ||
            (me.canEditTable &&
              this.editableObj[row][col] &&
              row !== 1 &&
              col > 3 &&
              col < currentColumn)
          ) {
            cp.readOnly = true;
            if (row !== 0) {
              cp.className = "htCenter noneditablehtInvalid htInvalid";
            } else {
              cp.className =
                "htCenter noneditablehtInvalid htInvalid total-cell";
            }
          }
          if (col > 3 && row === 2) {  
            cp.className = "color3";
            cp.type = "numeric";
          } 
        } else {
          if (col >= currentColumn && row !== 1) {
            cp.readOnly = true;
            if (row === 0) {
              cp.className = "htCenter volume-cells total-cell";
            } else {
              cp.className = "htCenter volume-cells";
            }
          }
        }
        if (me.isSameUOM) {
          if (
            me.canEditTable &&
            row === 0 &&
            (col >= currentColumn ||
              this.selectedFinancialYear.name === "All") &&
            col !== 16
          ) {
            if (this.selectedMaterialGroups) {
              if (
                this.selectedMaterialGroups.length <= 1 &&
                this.editableObj[row][col] &&
                (this.selectedForecast !== NET ||
                  this.tenantService.getTenant() === MEXICO)
              ) {  
                cp.className =
                  "htCenter volume-cells editablehtInvalid total-cell";
                cp.readOnly = false;
              } else {
                cp.className = "htCenter noneditablehtInvalid total-cell";
                cp.readOnly = true;
              }
            } else {  
              if (
                this.editableObj[row][col] &&
                (this.selectedForecast !== NET ||
                  this.tenantService.getTenant() === MEXICO)
              ) {
                cp.className =
                  "htCenter volume-cells editablehtInvalid total-cell";
                cp.readOnly = false;
              } else {
                cp.className = "htCenter noneditablehtInvalid total-cell";
                cp.readOnly = true;
              }
            }
          } else if (!me.canEditTable && row === 0 && col >= currentColumn) {
            if (col !== 15) {
              cp.className = "htCenter noneditablehtInvalid total-cell";
              cp.readOnly = true;
            } else {
              cp.className = "htCenter total-cell";
              cp.readOnly = true;
            }
          }
        } else {
          if (row === 0 && col > 3 && col !== 16) {
            cp.className = "htCenter noneditablehtInvalid total-cell";
            cp.readOnly = true;
          }
        }
        if (col === hot.countCols() - 1) {
          cp.className = (cp.className ? cp.className + ' ' : '') + 'last-column-bg';
      }

      if (col === 4) {
        cp.className = (cp.className ? cp.className + ' ' : '') + 'fifth-column-width';
    }

         // Highlight the last edited cell
         let pageNumber =  +localStorage.getItem("page");   
         const lastCell = JSON.parse(localStorage.getItem("lastCell") || "[]"); 
           if (lastCell.length > 0 && lastCell[0] === row && lastCell[1] === col && lastCell[2] === pageNumber) {
           cp.className =  'highlighted-cell'; 
         }
        cp.forceNumeric = true;
        return cp;
      },
      afterBeginEditing:(row, col) => { 
       let pageNum =  +localStorage.getItem("page"); 
        const selectedCell = [row, col,pageNum]; 
        localStorage.setItem("lastCell", JSON.stringify(selectedCell));
      }, 
      afterSelection: function (
        r,
        c,
        r2,
        c2,
        preventScrolling, 
      ) { 
        preventScrolling.value = true;
      },
      afterOnCellMouseDown: (event, coordinates) => { 
         this.setInputMaxLength();
      },
      beforeKeyDown: (event) => {
        this.setInputMaxLength();
      },
      afterChange: (changes, src) => { 
        //creating list of sku edited   
        const hot = me.hotRegisterer.getInstance(me.hotID);
        let column = null;
        let totalColumns = [];
        // Set 0 as default value if entered value is null, empty or string
        if (changes && changes.length > 0 && this.tenant === MEXICO) {
          for (let change of changes) {
            if (
              (changes && change[3] === null) ||
              (changes && change[3] === "") ||
              (changes && change[3] === undefined) ||
              (changes &&
                typeof change[3] === "string" &&
                (src === "edit" || src === "CopyPaste.paste"))
            ) {
              column = this.getColumn(columnArray, change[1]);
              if (
                change[2] !== null &&
                this.isNumber(Number(change[2])) &&
                change[2] !== "" &&
                change[2] !== undefined
              ) {
                me.data.data[change[0]][column.name] = change[2];
              } else {
                me.data.data[change[0]][column.name] = 0;
              }
  
              this.isDataEdited = true;
              this.dataEdited.emit(this.isDataEdited);
            }
          }
        }
        if (
          changes &&
          changes.length > 0 &&
          (this.tenant === SWAL || this.tenant === AF) &&
          this.selectedForecast != NET
        ) {
          for (let change of changes) {
            if (
              (changes && change[3] === null) ||
              (changes && change[3] === "") ||
              (changes && change[3] === undefined) ||
              (changes && change[3]! < 0) ||
              (changes &&
                typeof change[3] === "string" &&
                (src === "edit" || src === "CopyPaste.paste"))
            ) {
              column = this.getColumn(columnArray, change[1]);
              if (
                change[2] !== null &&
                this.isNumber(Number(change[2])) &&
                change[2] !== "" &&
                change[2] !== undefined
              ) {
                me.data.data[change[0]][column.name] = change[2];
              } else {
                me.data.data[change[0]][column.name] = 0;
              }
  
              if (change[3] >= 0) {
                this.isDataEdited = true;
                this.dataEdited.emit(this.isDataEdited);
              }
            }
          }
        }
  
        if (
          changes &&
          changes.length > 0 &&
          (src === "edit" || src === "CopyPaste.paste") &&
          (this.tenant === MEXICO ||
            ((this.tenant === SWAL || this.tenant === AF) &&
              this.selectedForecast != NET))
        ) {
          for (let change of changes) {
            if (
              changes &&
              change[3] !== null &&
              changes &&
              change[3] !== "" &&
              changes &&
              change[3] !== undefined &&
              changes &&
              typeof change[3] !== "string" &&
              (src === "edit" || src === "CopyPaste.paste")
            ) {
              column = this.getColumn(columnArray, change[1]);
              if (change[3] >= 0 || this.tenant === MEXICO) {
                //mexico allowed negative value on edit
                this.isDataEdited = true;
                this.dataEdited.emit(this.isDataEdited);
              }
  
              // Calculate Weightage and split based on ratio to all SKU
              if (change[0] === 0) {
                if (
                  (this.tenant === SWAL || this.tenant === AF) &&
                  this.selectedForecast != NET &&
                  change[3] < 0
                )
                  change[3] = change[2];
                let columnCount: number = hot.countRows() - 2;
                let weightage = [];
                for (let j = 2; j < hot.countRows(); j++) {
                  let weight = null;
                  let cellData = me.data.data[j][column.name];
                  if (this.isNumber(change[2])) {
                    if (isNaN(cellData / change[2])) {
                      weight = 100 / columnCount;
                    } else {
                      weight = (cellData / change[2]) * 100;
                    }
                  } else if (isNaN(change[2])) {
                    weight = 100 / columnCount;
                  } else {
                    weight = 100 / columnCount;
                  }
                  weightage[j] = weight;
                  let splitValue = Number(
                    (weightage[j] / 100) * change[3]
                  ).toFixed(1);
                  me.data.data[j][column.name] = splitValue;
                  const arr = [
                    me.data.data[j][this.languages.LABEL_PARTICULARS],
                    me.data.data[j][this.languages.LABEL_PARTICULARS3],
                  ];
                  if (!this.editedSkuList.includes(arr.toString())) {
                    this.editedSkuList.push(arr.toString());
                     this.editedSkuCodes.emit(this.editedSkuList);
                  }
                }
              } else {
                if (
                  (this.tenant === SWAL || this.tenant === AF) &&
                  this.selectedForecast != NET &&
                  change[3] < 0
                ) {
                  this.negativeValueFlag = true;
                }
              }
              let total: number = null;
              let netTotal: number = null;
              let volumeTotal: number = null;
              if (this.tenantService.getTenant() !== MEXICO) {
                let grossPrice = null;
                let cellData = hot.getDataAtCell(change[0], 0);
                 this.skuPriceMaster.forEach((ele) => {
                  if (ele.skuCode === cellData) {
                    grossPrice = ele.grossPrice;
                  }
                });
                if (column) {
                  const updatedTotal =
                    (Number(me.data.data[change[0]][column.name]) -
                      Number(change[2])) *
                    grossPrice;
                  const updatedVolumTotal =
                    Number(me.data.data[change[0]][column.name]) -
                    Number(change[2]);
                  total = Number(total) + updatedTotal;
                  volumeTotal = Number(volumeTotal) + updatedVolumTotal;
                }
                let year;
                year =
                  me.data.data[change[0]][this.languages.LABEL_PARTICULARS3]; //financial year of editaed cell
                totalColumns.push({
                  total: total,
                  column: column,
                  volume: volumeTotal,
                  financialYear: year,
                }); //pass year to update for particular year
              } else if (
                this.selectProductLength &&
                this.selectProductLength.length < 2
              ) {
                for (let change of changes) {
                  if (
                    changes &&
                    change[3] !== null &&
                    changes &&
                    change[3] !== "" &&
                    changes &&
                    change[3] !== undefined &&
                    changes &&
                    typeof change[3] !== "string" &&
                    (src === "edit" || src === "CopyPaste.paste")
                  ) {
                    column = this.getColumn(columnArray, change[1]);
                    let total: number = null;
                    let netTotal: number = null;
                    let volumeTotal: number = null;
                    let grossPrice = null;
                    let netPrice = null;
                    let cellData = hot.getDataAtCell(change[0], 0);
                    if (this.selectedAreas.length > 0) {
                      exit_loop: for (let skuprice of this.skuPriceMaster) {
                        if (skuprice.skuCode === cellData) {
                          skuprice.grossPrice.forEach((price) => {
                            if (this.selectedAreas.includes(price.geoId)) {
                              grossPrice = price.grossPrice;
                              if (
                                (this.userLevel && this.userLevel === PC) ||
                                this.getLevel() === PC
                              ) {
                                netPrice = price.netPrice;
                              }
                            }
                          });
                          break exit_loop;
                        }
                      }
                      if (column) {
                        const updatedTotal =
                          (Number(this.data.data[change[0]][column.name]) -
                            Number(change[2])) *
                          grossPrice;
                        total = Number(total) + updatedTotal;
                      }
                      if (
                        (this.userLevel && this.userLevel === PC) ||
                        this.getLevel() === PC
                      ) {
                        const updatedNetTotal =
                          (Number(this.data.data[change[0]][column.name]) -
                            Number(change[2])) *
                          netPrice;
                        if (column) {
                          netTotal = Number(netTotal) + updatedNetTotal;
                        }
                      }
                    } else {
                      exit_loop: for (
                        let p = 0;
                        p < this.skuPriceMaster.length;
                        p++
                      ) {
                        if (this.skuPriceMaster[p].skuCode === cellData) {
                          this.skuPriceMaster[p].grossPrice.forEach(
                            (price) => {
                              grossPrice = price.grossPrice;
                              if (
                                (this.userLevel && this.userLevel === PC) ||
                                this.getLevel() === PC
                              ) {
                                netPrice = price.netPrice;
                              }
                            }
                          );
                          break exit_loop;
                        }
                      }
                      if (column) {
                        const updatedTotal =
                          (Number(this.data.data[change[0]][column.name]) -
                            Number(change[2])) *
                          grossPrice;
                        total = Number(total) + updatedTotal;
                      }
                      if (
                        (this.userLevel && this.userLevel === PC) ||
                        this.getLevel() === PC
                      ) {
                        const updatedNetTotal =
                          (Number(this.data.data[change[0]][column.name]) -
                            Number(change[2])) *
                          netPrice;
                        if (column) {
                          netTotal = Number(netTotal) + updatedNetTotal;
                        }
                      }
                    }
                    if (column) {
                      const updatedVolumTotal =
                        Number(me.data.data[change[0]][column.name]) -
                        Number(change[2]);
                      volumeTotal = Number(volumeTotal) + updatedVolumTotal;
                    }
                    let year;
                    year =
                      me.data.data[change[0]][
                        this.languages.LABEL_PARTICULARS3
                      ]; //financial year of editaed cell
                    totalColumns.push({
                      total: total,
                      netTotal: netTotal,
                      column: column,
                      volume: volumeTotal,
                      financialYear: year,
                    }); //pass year to update for particular year
                  }
                }
              }
            }
          }
          if (
            this.tenantService.getTenant() !== MEXICO ||
            (this.selectProductLength && this.selectProductLength.length < 2)
          ) {
            // me.changeOtherRevenue(totalColumns);
          }
        }
        if (
          this.tenantService.getTenant() === MEXICO &&
          this.selectProductLength &&
          this.selectProductLength.length > 1
        ) {
          me.calculateTotal(changes, src, columnArray);
        }
        
        if (src === "edit") {
          const arr = [
            this.data.data[changes[0][0]][this.languages.LABEL_PARTICULARS],
            this.data.data[changes[0][0]][this.languages.LABEL_PARTICULARS3],
          ];
          if (!this.editedSkuList.includes(arr.toString())) {
            this.editedSkuList.push(arr.toString());
            this.editedSkuCodes.emit(this.editedSkuList);
          }
        }

        if(changes){
          for(let i=0; i<changes.length;i++){
            const change = changes[i];
            const row = change[0];
            const col = change[1];

            if(change[2] !== change[2]){
              me.lastEditCell = {row,col};
            }
          }
        }
      },
      afterRender: () => { 
        setTimeout(()=>{
          const element = document.querySelector('td.highlighted-cell')
          if(element){
            element.scrollIntoView({behavior: "auto", block: "center"}) 
          }
         },100)
      }
    };
  
    const hot = me.hotRegisterer.getInstance(me.hotID);
    if (hot) {
      hot.render();
    }
  }
  }
  
  
  mergeCellsForFyTm(data: any) {
    const dataValue = data.data;
     const mergingAmount = this.financialYearList.length - 1;
    const mergeCellInfo = [
      { row: 1, col: 0, rowspan: 1, colspan: 4 },
      { row: 1, col: 4, rowspan: 1, colspan: 4 },
      { row: 1, col: 8, rowspan: 1, colspan: 1 },
      { row: 1, col: 9, rowspan: 1, colspan: 4 },
    ];
    let i = 0;
    while (i < dataValue.length - 1) {
      if (mergingAmount === 2) {
        let val1 = null;
        let val2 = null;
        try {
          val1 = dataValue[i][this.languages.LABEL_PARTICULARS];
          val2 = dataValue[i + 1][this.languages.LABEL_PARTICULARS];
          if (val1 && val2) {
            if (val1 === val2) {
              mergeCellInfo.push({
                row: i,
                col: 0,
                rowspan: mergingAmount,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 1,
                rowspan: mergingAmount,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 2,
                rowspan: mergingAmount,
                colspan: 1,
              });
            }
          }
        } catch (error) {
          val1 = dataValue[i][this.languages.LABEL_PARTICULARS];
          val2 = null;
        }
      } else if (mergingAmount > 3) {
        let val1 = null;
        let val2 = null;
        let val3 = null;
        try {
          val1 = dataValue[i][this.languages.LABEL_PARTICULARS];
          val2 = dataValue[i + 1][this.languages.LABEL_PARTICULARS];
          val3 = dataValue[i + 2][this.languages.LABEL_PARTICULARS];
          if (val1 && val2 && val3) {
            if (val1 === val2 && val2 === val3) {
              mergeCellInfo.push({
                row: i,
                col: 0,
                rowspan: mergingAmount,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 1,
                rowspan: mergingAmount,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 2,
                rowspan: mergingAmount,
                colspan: 1,
              });
            } else if (val1 === val2 && val2 !== val3) {
              mergeCellInfo.push({
                row: i,
                col: 0,
                rowspan: mergingAmount - 1,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 1,
                rowspan: mergingAmount - 1,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 2,
                rowspan: mergingAmount - 1,
                colspan: 1,
              });
            }
          }
        } catch (error) {
          val1 = dataValue[i][this.languages.LABEL_PARTICULARS];
          val2 = dataValue[i + 1][this.languages.LABEL_PARTICULARS];
          val3 = null;
          if (val1 === val2) {
            mergeCellInfo.push({
              row: i,
              col: 0,
              rowspan: mergingAmount - 1,
              colspan: 1,
            });
            mergeCellInfo.push({
              row: i,
              col: 1,
              rowspan: mergingAmount - 1,
              colspan: 1,
            });
            mergeCellInfo.push({
              row: i,
              col: 2,
              rowspan: mergingAmount - 1,
              colspan: 1,
            });
          }
        }
      } else if (mergingAmount === 4) {
        let val1 = null;
        let val2 = null;
        let val3 = null;
        let val4 = null;
        try {
          val1 = dataValue[i][this.languages.LABEL_PARTICULARS];
          val2 = dataValue[i + 1][this.languages.LABEL_PARTICULARS];
          val3 = dataValue[i + 2][this.languages.LABEL_PARTICULARS];
          val4 = dataValue[i + 3][this.languages.LABEL_PARTICULARS];
          if (val1 && val2 && val3 && val4) {
            if (val1 === val2 && val2 === val3 && val3 === val4) {
              mergeCellInfo.push({
                row: i,
                col: 0,
                rowspan: mergingAmount,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 1,
                rowspan: mergingAmount,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 2,
                rowspan: mergingAmount,
                colspan: 1,
              });
            } else if (val1 === val2 && val2 === val3) {
              mergeCellInfo.push({
                row: i,
                col: 0,
                rowspan: mergingAmount - 1,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 1,
                rowspan: mergingAmount - 1,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 2,
                rowspan: mergingAmount - 1,
                colspan: 1,
              });
            } else if (val1 === val2) {
              mergeCellInfo.push({
                row: i,
                col: 0,
                rowspan: mergingAmount - 2,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 1,
                rowspan: mergingAmount - 2,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 2,
                rowspan: mergingAmount - 2,
                colspan: 1,
              });
            }
          }
        } catch (error) {
          val1 = dataValue[i][this.languages.LABEL_PARTICULARS];
          val2 = dataValue[i + 1][this.languages.LABEL_PARTICULARS];
          val3 = null;
          val4 = null;
          if (val1 === val2) {
            mergeCellInfo.push({
              row: i,
              col: 0,
              rowspan: mergingAmount - 2,
              colspan: 1,
            });
            mergeCellInfo.push({
              row: i,
              col: 1,
              rowspan: mergingAmount - 2,
              colspan: 1,
            });
            mergeCellInfo.push({
              row: i,
              col: 2,
              rowspan: mergingAmount - 2,
              colspan: 1,
            });
          }
        }
      }
      i = i + 1;
    }
    return mergeCellInfo;
  }

}

export const Timezone = {
    TIMEZONE: 'IST',
    OFFSET: 'UTC+5:30',
}
export const Languages = {
    LABEL_DEFAULT_LANG_ID: '1',
    LABEL_FILE_PROCESSING: "File Processing",
    LABEL_MASTER_FILE: "Master File",
    LABEL_TRANSACTION_FILE: "Transaction File",
    LABEL_FILE_NAME: "File Name",
    LABEL_STATUS: "Status",
    LABEL_ACTION:"Action",
    LABEL_PROCESSING_ACTION:"Processing Action",
    LABEL_PROGRESS: "Progress",
    LABEL_FILE_ARRIVAL_TIME: "File Arrival Time",
    LABEL_FILE_PROCESSED_TIME:"File Processed Time",
    LABEL_DOWNLOAD: "Download",
    LABEL_ERROR_REPORT:"Error Report",
    LABEL_RETRY: "Retry",
    LABEL_START:"Start",
    LABEL_VIEW: "View",

    LABEL_CONFIGURE_WINDOW: "Confiure Window",
    LABEL_USER_LEVEL: "User Level",
    LABEL_START_DATE: "Start Date",
    LABEL_END_DATE: "End Date",
    LABEL_WINDOW_TIME: "Time",
    LABEL_CLOSE_NOW: "Close Now",
    LABEL_COMMENTS: "Comments",
    LABEL_FROM_DATE: "From Date",
    LABEL_TO_DATE: "To Date",
    LABEL_ADD:"Add",
    LABEL_EDIT:"Edit",
    LABEL_UPDATE: "Update",
    LABEL_CANCEL: "Cancel",
    LABEL_PUSH_FILE_TO_SAP: "Push File For SAP-APO System",
    LABEL_FOREACASTING_CYCLE: "Forecasting Cycle",
    LABEL_FILE_GENERATION_TIME: "File Generation Time",
    LABEL_PROCESSING_HISTORY: "Processing History",
    LABEL_FORECASTING_SHEET: "Forecasting Sheet",
    LABEL_WINDOW_START_DATE: "Window Start Date",
    LABEL_WINDOW_END_DATE: "Window End Date",
    LABEL_ADOPTION_STATISTICS: "Adoption Statistics",
    LABEL_REPORTS: "Reports",
    LABEL_CREATE_NOTIFICATION: "Notifications",
    LABEL_FINANCIAL_YEAR: "Financial Year",
    LABEL_MATERIAL_GROUP: "Material Group",
    LABEL_PRODUCT_BRAND: "Product Brand",
    LABEL_ERROR_CATEGORY: "Error Category",
    LABEL_SKU: "SKU",
    LABEL_ZONE: "Zone",
    LABEL_REGION: "Region",
    LABEL_TERRITORY: "Territory",
    LABEL_CLOSE: "Close",
    LABEL_ALL_VALUES_IN_INR: "All Values in INR",
    LABEL_CRORES: "Crores",
    LABEL_LAKHS: "Lakhs",
    LABEL_ABSOLUTE: "Absolute",
    LABEL_PRODUCT_REVENUE: "Product Revenue",
    LABEL_PRODUCT_VOLUMES: "Product Volumes",
    LABEL_PREDICTIVE_FORECAST: "Predictive Forecast",
    LABEL_Y1_YEAR_VOLUME:'Y-1 Year Volume',
    LABEL_SKU_SPLITS_BASED: "SKU Splits Based",
    LABEL_BRAND: "Brand",
    LABEL_SKU_MATERIAL: "SKU Material",
    LABEL_CUSTOMER: "Customer",

    LABEL_PROFILE_DETAILS:"Profile Details",
    LABEL_CHANGE_LEVEL:"Change Level",
    LABEL_CHANGE_PROFIT_CENTER:"Change Profit Center",
    LABEL_CHANGE:"Change",
    LABEL_LOGOUT:"Logout",

    LABEL_PROCESSALL_MASTER:"Consider All Master Files For Processing",
    LABEL_GENERATE_REPORT:"Generate Report",
    LABEL_GENERATED_REPORTS:"Generated Reports",
    LABEL_SAVE:"Save",

    LABEL_REQUESTED_ID:"Request Id",
    LABEL_REQUESTED_DATE:"Requested Date",
    LABEL_REPORT_GENERATED_DATE:"Report Generated Date",

    LABEL_OUTPUT_CREATED_STATUS : 'File Generated Successfully',
    LABEL_OUTPUT_INITIATED_STATUS : 'File Generation Started',
    LABEL_OUTPUT_COMPLETED_STATUS : 'File Moved to FTP Server Successfully',
    LABEL_OUTPUT_FAILED_STATUS : 'File Generation Failed',

    LABEL_PROCESSED_SUCCESS_STATUS:'File Processed Successfully',
    LABEL_PROCESS_FAILED_STATUS:'File Processing Failed',
    LABEL_UNDER_PROCESS_STATUS:'File Under Processing',
    LABEL_PENDING_PROCESS_STATUS:'File Processing Pending',
    LABEL_FILE_RECEIVED_STATUS:'File Recieved Successfully',
    LABEL_FILE_INJECTION_PROCESS_STATUS:'File Injection In Progress',
    LABEL_FILE_INJECTION_FAILED_STATUS:'File Injection Failed',

    LABEL_DOWNLOAD_REQUEST_ACCEPTED_STATUS:'Report Generation Request is accepted',
    LABEL_DOWNLOAD_REQUEST_SUCCESSFUL_STATUS:'Report downloaded successfully',
    LABEL_FORECAST_UPDATE_SUCCESSFUL_STATUS:'Forecasting updated successfully',
    LABEL_SELECT_FINANCIAL_YEAR:'Please select financial year',
    LABEL_SAME_UOM:'Selected products contain different units of measurement. Please select products having same UOM.',

    LABEL_LEVEL_NOT_NULL:'Level cannot be null',

    LABEL_MT_KL:'MT-KL',
    LABEL_KG_LT_Absolute:'KG-LT / Absolute',
    LABEL_MT_KL_1KEACHES:'MT-KL - 1K Eaches',
    LABEL_MT_KL_EACHES_ABSOLUTE:'KG-LT - Eaches / Absolute',

    LABEL_MILLIONS:"Millions",
    LABEL_REFRESHING_DATA:"(Refreshing Data)",
    LABEL_ALL_VALUES_IN_MXN:"All Values in MXN",
    LABEL_ALL_VALUES_IN:"All Values in",
    LABEL_FORECAST_DATA_IN_ABSOLUTE_NUMBER:'Forecast data is captured in Absolute number',
    LABEL_SBU:"SBU",
    LABEL_FILTER:"Filter",
    LABEL_STATIC_OPQ:"Static OPQ",
    LABEL_DYNAMIC_ABC:"Dynamic ABC",
    LABEL_SEARCHBY:"Search By",
    LABEL_MOLECULE:"Molecule",
    LABEL_APPLY:"Apply",
    LABEL_CLEAR:"Clear",
    LABEL_AREA:"Area",
    LABEL_SALES_QTY:"Sales Qty",
    LABEL_POG:"POG (Product on Ground)",
    LABEL_NET_PRICE:"NET PRICE",
    LABEL_GROSS_PRICE:"GROSS PRICE",
    LABEL_EVENT:"EVENT",

    LABEL_NO_DATA_AVAILABLE:"No Data Available",
    LABEL_LEVEL:"Level",
    LABEL_CYCLE_NAME:"Cycle Name",
    LABEL_SELECT_ALL:"Select All",
    LABEL_UNSAVED_CHANGES:"You have unsaved changes, that will be lost. Do you want to save them?",
    LABEL_NO:"No",
    LABEL_YES_SURE:"Yes, Sure",
    LABEL_SCROLL_TO_BOTTOM:"Scroll to Bottom",
    LABEL_SCROLL_TO__TOP:"Scroll to Top",
    LABEL_ADVANCE_FILTER:"Advance Filter",
    LABEL_CLEAR_FILTER:"Clear Filter",
    LABEL_JAN:"JAN",
    LABEL_APR:"APR",
    LABEL_MAY:"MAY", 
    LABEL_JUN:"JUN", 
    LABEL_JUL:"JUL",
    LABEL_AUG:"AUG", 
    LABEL_SEP:"SEP", 
    LABEL_OCT:"OCT", 
    LABEL_NOV:"NOV", 
    LABEL_DEC:"DEC", 
    LABEL_FEB:"FEB", 
    LABEL_MAR:"MAR",
    LABEL_UPDATED_WINDOW_MSG:"You have updated the current window details for",
    LABEL_PROCEED_CONFIRMATION:"Are you sure you want to proceed with this?",
    LABEL_UNSAVEDCHANGES_LEAVE_PAGE_CONFIRMATION:"You have unsaved changes. Are you sure you want to leave this page?",
    LABEL_WISH_TO:"Do you wish to",
    LABEL_SELECT_PC_AS:"the selected profit center as a",
    LABEL_PREFERRED_PROFIT_CENTER:"Preferred Profit Center",
    LABEL_FUTURE_REFERENCE:"for future reference",
    LABEL_FORECAST:"Forecast",
    LABEL_PARTICULARS:"PARTICULARS",
    LABEL_PARTICULARS1:"PARTICULARS1",
    LABEL_PARTICULARS2:"PARTICULARS2",
    LABEL_PARTICULARS3:"PARTICULARS3",
    LABEL_VOLUME:'VOLUME',
    LABEL_TOTAL:"TOTAL",
    LABEL_RETURN:"Return",
    LABEL_NET:"Net",
    LABEL_GROSS:"Gross",
    LABEL_THRESHOLD:"Threshold",
    LABEL_NEGATIVE_VALUES_NOT_ALLOWED:"Negative Values are not allowed",
    LABEL_NEGATIVE_FIGURES:"Negative Figures",
    LABEL_THRESHOLD_LIMIT_CROSSED:"Threshold Limit Crossed",
    LABEL_LOCAL_TIME:"Local Time",
    LABEL_MASTER_FILE_NOT_PROCESSED_POPUP_CONFIRMATION:"All Master files have not been processed, Are you sure that you want to continue processing?",
    LABEL_FILE_IS_PROCESSING_MESSAGE :"File is being processed",
    LABEL_MASTER_FILES_ARE_PROCESSING_MESSAGE:"Master Files are being processed.",
    LABEL_MASTER_FILE_CONFIRMATION_MESSAGE: 'Are you sure you want to process this Master file?',
    LABEL_MASTER_FILE_ALL_CONFIRMATION_MESSAGE :'Are you sure you want to process all the Master data files?',
    LABEL_SHOWING:'Showing',
    LABEL_TO:'to',
    LABEL_OF:'of',
    LABEL_ENTRIES:'entries'
}

export const DefaultLanguages = {
    LABEL_DEFAULT_LANG_ID: '1',
    LABEL_FILE_PROCESSING: "File Processing",
    LABEL_MASTER_FILE: "Master File",
    LABEL_TRANSACTION_FILE: "Transaction File",
    LABEL_FILE_NAME: "File Name",
    LABEL_STATUS: "Status",
    LABEL_ACTION:"Action",
    LABEL_PROCESSING_ACTION:"Processing Action",
    LABEL_PROGRESS: "Progress",
    LABEL_FILE_ARRIVAL_TIME: "File Arrival Time",
    LABEL_FILE_PROCESSED_TIME:"File Processed Time",
    LABEL_DOWNLOAD: "Download",
    LABEL_ERROR_REPORT:"Error Report",

    LABEL_CONFIGURE_WINDOW: "Confiure Window",
    LABEL_USER_LEVEL: "User Level",

    LABEL_START_DATE: "Start Date",
    LABEL_END_DATE: "End Date",
    LABEL_WINDOW_TIME: "Time",
    LABEL_CLOSE_NOW: "Close Now",
    LABEL_COMMENTS: "Comments",
    LABEL_FROM_DATE: "From Date",
    LABEL_TO_DATE: "To Date",
    LABEL_ADD:"Add",
    LABEL_EDIT:"Edit",
    LABEL_UPDATE: "Update",
    LABEL_CANCEL: "Cancel",
    LABEL_PUSH_FILE_TO_SAP: "Push File For SAP-APO System",
    LABEL_FOREACASTING_CYCLE: "Forecasting Cycle",
    LABEL_FILE_GENERATION_TIME: "File Generation Time",
    LABEL_PROCESSING_HISTORY: "Processing History",
    LABEL_START: "Start",
    LABEL_RETRY: "Retry",
    LABEL_VIEW: "View",
    LABEL_FORECASTING_SHEET: "Forecasting Sheet",
    LABEL_WINDOW_START_DATE: "Window Start Date",
    LABEL_WINDOW_END_DATE: "Window End Date",
    LABEL_ADOPTION_STATISTICS: "Adoption Statistics",
    LABEL_REPORTS: "Reports",
    LABEL_CREATE_NOTIFICATION: "Notifications",
    LABEL_FINANCIAL_YEAR: "Financial Year",
    LABEL_MATERIAL_GROUP: "Material Group",
    LABEL_PRODUCT_BRAND: "Product Brand",
    LABEL_ERROR_CATEGORY: "Error Category",
    LABEL_SKU: "SKU",
    LABEL_ZONE: "Zone",
    LABEL_REGION: "Region",
    LABEL_TERRITORY: "Territory",
    LABEL_CLOSE: "Close",
    LABEL_ALL_VALUES_IN_INR: "All Values in INR",
    LABEL_CRORES: "Crores",
    LABEL_LAKHS: "Lakhs",
    LABEL_ABSOLUTE: "Absolute",
    LABEL_PRODUCT_REVENUE: "Product Revenue",
    LABEL_PRODUCT_VOLUMES: "Product Volumes",
    LABEL_PREDICTIVE_FORECAST: "Predictive Forecast",
    LABEL_Y1_YEAR_VOLUME:'Y-1 Year Volume',
    LABEL_SKU_SPLITS_BASED: "SKU Splits Based",
    LABEL_BRAND: "Brand",
    LABEL_SKU_MATERIAL: "SKU Material",
    LABEL_CUSTOMER: "Customer",

    LABEL_PROFILE_DETAILS:"Profile Details",
    LABEL_CHANGE_LEVEL:"Change Level",
    LABEL_CHANGE_PROFIT_CENTER:"Change Profit Center",
    LABEL_CHANGE:"Change",
    LABEL_LOGOUT:"Logout",

    LABEL_PROCESSALL_MASTER:"Consider All Master Files For Processing",
    LABEL_GENERATE_REPORT:"Generate Report",
    LABEL_GENERATED_REPORTS:"Generated Reports",
    LABEL_SAVE:"Save",

    LABEL_REQUESTED_ID:"Request Id",
    LABEL_REQUESTED_DATE:"Requested Date",
    LABEL_REPORT_GENERATED_DATE:"Report Generated Date",

    LABEL_OUTPUT_CREATED_STATUS : 'File Generated Successfully',
    LABEL_OUTPUT_INITIATED_STATUS : 'File Generation Started',
    LABEL_OUTPUT_COMPLETED_STATUS : 'File Moved to FTP Server Successfully',
    LABEL_OUTPUT_FAILED_STATUS : 'File Generation Failed',

    LABEL_PROCESSED_SUCCESS_STATUS:'File Processed Successfully',
    LABEL_PROCESS_FAILED_STATUS:'File Processing Failed',
    LABEL_UNDER_PROCESS_STATUS:'File Under Processing',
    LABEL_PENDING_PROCESS_STATUS:'File Processing Pending',
    LABEL_FILE_RECEIVED_STATUS:'File Recieved Successfully',
    LABEL_FILE_INJECTION_PROCESS_STATUS:'File Injection In Progress',
    LABEL_FILE_INJECTION_FAILED_STATUS:'File Injection Failed',

    LABEL_DOWNLOAD_REQUEST_ACCEPTED_STATUS:'Report Generation Request is accepted',
    LABEL_DOWNLOAD_REQUEST_SUCCESSFUL_STATUS:'Report downloaded successfully',
    LABEL_FORECAST_UPDATE_SUCCESSFUL_STATUS:'Forecasting updated successfully',
    LABEL_SELECT_FINANCIAL_YEAR:'Please select financial year',
    LABEL_SAME_UOM:'Selected products contain different units of measurement. Please select products having same UOM.',
    LABEL_LEVEL_NOT_NULL:'Level cannot be null',

    LABEL_MT_KL:'MT-KL',
    LABEL_KG_LT_Absolute:'KG-LT / Absolute',
    LABEL_MT_KL_1KEACHES:'MT-KL - 1K Eaches',
    LABEL_MT_KL_EACHES_ABSOLUTE:'KG-LT - Eaches / Absolute',

    LABEL_MILLIONS:"Millions",
    LABEL_REFRESHING_DATA:"(Refreshing Data)",
    LABEL_ALL_VALUES_IN_MXN:"All Values in MXN",
    LABEL_ALL_VALUES_IN:"All Values in",
    LABEL_FORECAST_DATA_IN_ABSOLUTE_NUMBER:'Forecast data is captured in Absolute number',
    LABEL_SBU:"SBU",
    LABEL_FILTER:"Filter",
    LABEL_SEARCHBY:"Search By",
    LABEL_STATIC_OPQ:"Static OPQ",
    LABEL_DYNAMIC_ABC:"Dynamic ABC",
    LABEL_MOLECULE:"Molecule",
    LABEL_APPLY:"Apply",
    LABEL_CLEAR:"Clear",
    LABEL_SALES_QTY:"Sales Qty",
    LABEL_POG:"POG (Product on Ground)",
    LABEL_NET_PRICE:"NET PRICE",
    LABEL_GROSS_PRICE:"GROSS PRICE",
    LABEL_EVENT:"EVENT",

    LABEL_NO_DATA_AVAILABLE:"No Data Available",
    LABEL_LEVEL:"Level",
    LABEL_CYCLE_NAME:"Cycle Name",
    
    LABEL_SELECT_ALL:"Select All",
    LABEL_UNSAVED_CHANGES:"You have unsaved changes, that will be lost. Do you want to save them?",
    LABEL_NO:"No",
    LABEL_YES_SURE:"Yes, Sure",
    LABEL_SCROLL_TO_BOTTOM:"Scroll to Bottom",
    LABEL_SCROLL_TO__TOP:"Scroll to Top",
    LABEL_ADVANCE_FILTER:"Advance Filter",
    LABEL_CLEAR_FILTER:"Clear Filter",
    LABEL_AREA:"Area",
    LABEL_JAN:"JAN",
    LABEL_APR:"APR",
    LABEL_MAY:"MAY", 
    LABEL_JUN:"JUN", 
    LABEL_JUL:"JUL",
    LABEL_AUG:"AUG", 
    LABEL_SEP:"SEP", 
    LABEL_OCT:"OCT", 
    LABEL_NOV:"NOV", 
    LABEL_DEC:"DEC", 
    LABEL_FEB:"FEB", 
    LABEL_MAR:"MAR",
    LABEL_UPDATED_WINDOW_MSG:"You have updated the current window details for",
    LABEL_PROCEED_CONFIRMATION:"Are you sure you want to proceed with this?",
    LABEL_UNSAVEDCHANGES_LEAVE_PAGE_CONFIRMATION:"You have unsaved changes. Are you sure you want to leave this page?",
    LABEL_WISH_TO:"Do you wish to",
    LABEL_SELECT_PC_AS:"the selected profit center as a",
    LABEL_PREFERRED_PROFIT_CENTER:"Preferred Profit Center",
    LABEL_FUTURE_REFERENCE:"for future reference",
    LABEL_FORECAST:"Forecast",
    LABEL_PARTICULARS:"PARTICULARS",
    LABEL_PARTICULARS1:"PARTICULARS1",
    LABEL_PARTICULARS2:"PARTICULARS2",
    LABEL_PARTICULARS3:"PARTICULARS3",
    LABEL_VOLUME:'VOLUME',
    LABEL_TOTAL:"TOTAL",
    LABEL_RETURN:"Return",
    LABEL_NET:"Net",
    LABEL_GROSS:"Gross",
    LABEL_THRESHOLD:"Threshold",
    LABEL_NEGATIVE_VALUES_NOT_ALLOWED:"Negative Values not allowed",
    LABEL_NEGATIVE_FIGURES:"Negative Figures",
    LABEL_THRESHOLD_LIMIT_CROSSED:"Threshold Limit Crossed",
    LABEL_LOCAL_TIME:"Local Time",
    LABEL_MASTER_FILE_NOT_PROCESSED_POPUP_CONFIRMATION:"Master files have not been processed, Are you sure that you want to continue processing?",
    LABEL_FILE_IS_PROCESSING_MESSAGE:"File is being processed",
    LABEL_MASTER_FILES_ARE_PROCESSING_MESSAGE:"Master Files are being processed.",
    LABEL_MASTER_FILE_CONFIRMATION_MESSAGE: 'Are you sure you want to process this Master file?',
    LABEL_MASTER_FILE_ALL_CONFIRMATION_MESSAGE :'Are you sure you want to process all the Master data files?',
    LABEL_SHOWING:'Showing',
    LABEL_TO:'to',
    LABEL_OF:'of',
    LABEL_ENTRIES:'entries'
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { TenantService } from '../services/tenant.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private tenantService: TenantService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const profile = JSON.parse(localStorage.getItem('profile'));
    const token = localStorage.getItem('token');
    const defaultTenant = localStorage.getItem('defaultTenant');
    if (token) {
      if (!defaultTenant) {
        this.router.navigate(['/profit-center']);
        return false;
      } else {
        if (this.authService.isAdmin()) {
          return true;
        } else {
          const tenant = this.tenantService.getTenant();
          const assignedLevel = localStorage.getItem('assignedLevel');
          const defaultGeography = localStorage.getItem('defaultGeography');
          if (profile.assignedLevels.length > 1 && !assignedLevel) {
            this.router.navigate(['/level']);
            return false;
          } else if (profile.hasGeography && !defaultGeography) {
            this.router.navigate(['/geography']);
            return false;
          } else if (tenant) {
            this.router.navigate(["/" + tenant.toLowerCase()]);
            return false;
          }
        }
      }
    } else {
      this.router.navigate(["/login"]);
    }
  }

}

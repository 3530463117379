import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrMessageService } from 'src/app/core/services/toast-message.service';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {

  @Input() description;
  @Input() errorCode;

  constructor(public modal: NgbActiveModal, private toastMessageService: ToastrMessageService) { }

  ngOnInit() {
    //ngOninit 
  }

  submit() {
    this.modal.close();
  }

  copyData() {
    var copyText: any = document.getElementById('error-content-dialog');
    copyText.select();
    document.execCommand("copy");
    this.toastMessageService.successMessage("Text copied to clipboard");
  }

}

import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgSelectConfig, NgSelectComponent } from '@ng-select/ng-select';
import { FormGroup } from '@angular/forms';
import { LanguagesService } from 'src/app/core/services/languages.service';
import { Languages } from 'src/app/core/constants/languages.constants';

@Component({
  selector: 'app-single-select',
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SingleSelectComponent implements OnInit, OnChanges {

  @ViewChild(NgSelectComponent, { static: false }) ngSelectComponent: NgSelectComponent;

  @Input() data;
  @Input() bindLabel;
  @Input() bindValue;
  @Input() placeholder;
  @Input() clear;
  @Input() searchableFlag;
  @Input() form: FormGroup;
  @Input() control;
  @Input() notFoundText;
  @Input() value;
  languages = Languages;

  @Output() change = new EventEmitter();

  events: Event = null;

  constructor(private config: NgSelectConfig) {
   // config.notFoundText = this.notFoundText?this.notFoundText:this.languages.LABEL_NO_DATA_AVAILABLE;
  }

  ngOnChanges(changes: SimpleChanges) {
      this.config.notFoundText = this.notFoundText?this.notFoundText:this.languages.LABEL_NO_DATA_AVAILABLE;
  }

  ngOnInit() {
    this.config.notFoundText = this.notFoundText?this.notFoundText:this.languages.LABEL_NO_DATA_AVAILABLE;
  }


}

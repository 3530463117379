import { Languages } from "../constants/languages.constants";

export const getFiscalMonth = () => {
     let assignedLevel = localStorage.getItem("assignedLevel");
      let level = localStorage.getItem("level");
   const languages=Languages;
   const months1 = ["APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC", "JAN", "FEB", "MAR"]
   let months = []
   let monthObj = [];

    if(assignedLevel == "Territory Manager" || level == "TM"){
        months = [languages.LABEL_JAN, languages.LABEL_FEB, languages.LABEL_MAR, languages.LABEL_APR, languages.LABEL_MAY, languages.LABEL_JUN, languages.LABEL_JUL, languages.LABEL_AUG, languages.LABEL_SEP, languages.LABEL_OCT, languages.LABEL_NOV, languages.LABEL_DEC]
    }else{
        months = [languages.LABEL_APR, languages.LABEL_MAY, languages.LABEL_JUN, languages.LABEL_JUL, languages.LABEL_AUG, languages.LABEL_SEP, languages.LABEL_OCT, languages.LABEL_NOV, languages.LABEL_DEC, languages.LABEL_JAN, languages.LABEL_FEB, languages.LABEL_MAR]
    }
    months.forEach((month, i) => {
        let id = null
        if (i + 1 > 9) {
            id = (i + 1).toString();
        }
        else {
            id = "0" + (i + 1);
        }

        monthObj.push({ month: month, id: id });
    });

    return monthObj;
}
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AdminNotificationService {
  constructor(private http: HttpClient) {}

  sendNotification(data): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/api/v1/notifications/create`,
      data
    );
  }
  viewNotificationsUser(geographyId, page, size): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/api/v1/notifications/geography/${geographyId}?page=${page}&size=${size}`
    );
  }
  viewNotificationsAdmin(page, size): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/api/v1/notifications?page=${page}&size=${size}`
    );
  }
  markAsReadNotificationUser(notificationId, geographyCode): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/api/v1/notifications/update/${notificationId}/geography/${geographyCode}/mark-as-read`,
      null
    );
  }
  markAsReadNotificationAdmin(notificationId): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/api/v1/notifications/update/${notificationId}/mark-as-read`,
      null
    );
  }
  //level wise notification table api
  viewAdminNotifications(levelId: any, page, size): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/api/v1/notifications/level/${levelId}?page=${page}&size=${size}`
    );
  }
  //auto sync notification api for unreadcount
  viewAutoNotificationsUser(geographyId, page, size): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/api/v1/notifications/geography/${geographyId}/sync/?page=${page}&size=${size}`
    );
  }
  viewAutoNotificationsAdmin(page, size): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/api/v1/notifications/sync?page=${page}&size=${size}`
    );
  }
}

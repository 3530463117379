import { SessionTimeOutComponent } from './../../shared/session-time-out/session-time-out.component';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ToastrMessageService } from '../services/toast-message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorDialogComponent } from 'src/app/shared/error-dialog/error-dialog.component';
import { environment } from 'src/environments/environment';
import { InfoDialogComponent } from 'src/app/shared/info-dialog/info-dialog.component';
import { LOGIN005, LOGINEXPIRED } from '../constants/string.constants';

const GET = 'get';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(
    private router: Router,
    private authService: AuthService,
    private toastMessageService: ToastrMessageService,
    private modalService: NgbModal
  ) { }
  sessionTimeoutFlag=false;
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!window.navigator.onLine) {
      this.toastMessageService.errorMessage('No Internet Connection!');
      return throwError(new HttpErrorResponse({ error: 'Internet is required.' }));
    } else {
      const token: any = localStorage.getItem('token');

      if (token) {
        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
      }

      request = request.clone({ headers: request.headers.set('Cache-Control', 'no-cache') });
      request = request.clone({ headers: request.headers.set('Pragma', 'no-cache') });
      request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
          return event;
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401 ) {
             if(!this.sessionTimeoutFlag) {this.sessionTimeoutFlag=true;
              this.openSessionTimeoutPopup(error);}
          }
          else if (error.status === 404) {
            this.toastMessageService.errorMessage("Application is currently down. Please try again later");
          } else if (error.status === 403) {
            this.toastMessageService.errorMessage("Unauthorized Access. Contact your administrator");
          } 
          else if (error.error && error.error.status && Number(error.error.status) === 500) {
            if (error.error.errorUUID) {
              const dialogRef = this.modalService.open(ErrorDialogComponent, { centered: true });
              dialogRef.componentInstance.description = "An error occurred while processing your request. Please copy the above code and contact your administrator for more details.";
              dialogRef.componentInstance.errorCode = error.error.errorUUID;
            } else if (error.error.errorCode===LOGIN005) {
              const dialogRef = this.modalService.open(InfoDialogComponent, { centered: true });
              dialogRef.componentInstance.description = error.error.errorMessage;
              dialogRef.componentInstance.errorCode = error.error.errorCode;
            } else {
              this.toastMessageService.errorMessage(error.error.errorMessage);
            }
          } else {
            this.toastMessageService.errorMessage("Oops, Something went wrong!");
          }
          return throwError(error);
        })
      );
    }
  }

  openSessionTimeoutPopup(error?) {
    const dialogRef = this.modalService.open(SessionTimeOutComponent, { centered: true,backdrop: 'static',
    keyboard: false });
    dialogRef.componentInstance.response.subscribe(res => {
      if (res) {
        (error.error && error.error.message && error.error.message===LOGINEXPIRED)?this.logout():this.sessionTimeOutLogout();
        this.sessionTimeoutFlag=false;
      }
    })
  }
  logout() {
    if (environment.production) {
      this.authService.logout().subscribe(_res => {
        //response
        this.authService.errorLogout();
      }, _err => {

        //error
        console.log(_err);
      });
    } else {
      this.authService.logoutEmployee().subscribe(res => {
        // successful logout employee response
      }, err => {
         //error response
      });
    }
  }
  sessionTimeOutLogout(){
    this.authService.errorLogout();
    this.modalService.dismissAll();
    this.router.navigate(['']);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardApiService {

  constructor(private http: HttpClient) { }

  getProfitCenterConfig(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/profit-center/configuration`).pipe(map(config => {
      localStorage.setItem('config', JSON.stringify(config));
      return config;
    }));
  }

  getProfitCenterConfigCheck(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/profit-center/configuration`).pipe(map(config => {
      return config;
    }));
  }

  getForecastingTableOnDemand(identifier, geoId): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/forecasting/sku-split/${geoId}`, identifier);
  }

  getAdminForecastingTableOnDemand(identifier, geoId, levelId): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/forecasting-windows/sku-split?geoId=${geoId}&levelId=${levelId}`, identifier);
  }
//Need to send checboxes
  getForecastingData(filter, geoId): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/forecasting/${geoId}`, filter);
  }

  getAdminForecastingData(filter, geoId?:any, levelId?:any): Observable<any> {   
    let geoID = geoId === undefined ? '' : geoId; 
    return this.http.post(`${environment.apiUrl}/api/v1/forecasting-windows?geoId=${geoID}&levelId=${levelId}`, filter);
  }

  updateForecastingData(forecastData, geoId): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/forecasting/update/${geoId}?type=null`, forecastData);
  }
  autoUpdateForecastingData(forecastData,geoId): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/forecasting/update/${geoId}/sync?type=null`, forecastData);
  }
  updateForecastingDataSwalAf(forecastData, forecastType,geoId): Observable<any> {
     return this.http.post(`${environment.apiUrl}/api/v1/forecasting/update/${geoId}?type=`+forecastType, forecastData);
  }
  autoUpdateForecastingDataSwalAf(forecastData, forecastType,geoId): Observable<any> {
     return this.http.post(`${environment.apiUrl}/api/v1/forecasting/update/${geoId}/sync?type=`+forecastType, forecastData);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {

  @Input() description;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit() {
    //ngoninit
  }

  submit() {
    this.modal.close();
  }

}

import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
  EventEmitter,
  Output,
} from "@angular/core";
import Handsontable from "handsontable";
import {
  LAKHS,
  CRORES,
  ABSOLUTE,
  MILLIONS,
  PARTICULARS1,
  PARTICULARS,
  TOTAL,
  PARTICULARS2,
} from "src/app/core/constants/string.constants";
import { HotTableComponent, HotTableRegisterer } from "@handsontable/angular";
import { getCurrentMonth } from "src/app/core/helpers/getCurrentMonth.helper";
import { TenantService } from "src/app/core/services/tenant.service";
import { NgbCollapse } from "@ng-bootstrap/ng-bootstrap";
import { Languages } from "src/app/core/constants/languages.constants";
import { AF, MEXICO, SWAL } from "src/app/core/constants/tenant.constants";
import { FilterApiService } from "src/app/core/services/filter-api.service";

@Component({
  selector: "app-common-product-revenue",
  templateUrl: "./common-product-revenue.component.html",
  styleUrls: ["./common-product-revenue.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CommonProductRevenueComponent implements OnInit, OnChanges {
  @ViewChild("revenueHot", { static: false }) revenueHot: HotTableComponent;

  @Input() data;
  @Input() selectedFinancialYear;
  @Input() financialYearList;

  languages = Languages;
  hotSettings: Handsontable.GridSettings = null;
  hotData = null;
  selectedRevenue = LAKHS;
  tenant;

  //constants to use in template html
  LAKHS = LAKHS;
  CRORES = CRORES;
  AF = AF;
  SWAL = SWAL;
  MEXICO = MEXICO;
  ABSOLUTE = ABSOLUTE;
  MILLIONS = MILLIONS;

  isCollapsed = false;
  hotID = "RevenueHot";

  @Output() finYearChange = new EventEmitter();

  constructor(
    private chRef: ChangeDetectorRef,
    private tenantService: TenantService,
    private hotRegisterer: HotTableRegisterer,
    private filterService: FilterApiService
  ) {
    this.tenant = this.tenantService.getTenant();
    this.selectedRevenue = this.tenant === MEXICO ? MILLIONS : LAKHS;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.data && changes.data.currentValue) {
      this.hotSettings = null;
      this.toggleRevenue(this.selectedRevenue);
    }
  }

  ngOnInit() {
    this.selectedRevenue = this.tenant === MEXICO ? MILLIONS : LAKHS;
  }

  toggleFinYear(year) {
    if (year.id !== "All") {
      this.finYearChange.emit(year);
    }else if (year.id === "All") {
      const finYear = this.financialYearList;
      finYear.shift();
      this.finYearChange.emit(finYear);
    }
  }

  toggleRevenue(field) {
    this.selectedRevenue = field;
    for (let dataObject of this.data.data) {
      let total = null;
      for (const [key, value] of Object.entries(dataObject)) {
        if (
          key !== this.languages.LABEL_PARTICULARS &&
          key !== this.languages.LABEL_PARTICULARS1 &&
          key !== this.languages.LABEL_PARTICULARS2 &&
          key !== this.languages.LABEL_TOTAL
        ) {
          total = total + Number(value);
        }
      }
      dataObject[this.languages.LABEL_TOTAL] = total;
    }
    if (field === LAKHS) {
      const newData = this.calculateRevenue(this.data, 5, 1);
      this.hotData = newData.data;
      this.generateTable(newData);
    }
    if (field === CRORES) {
      const newData = this.calculateRevenue(this.data, 7, 2);
      this.hotData = newData.data;
      this.generateTable(newData);
    }
    if (field === MILLIONS) {
      const newData = this.calculateRevenue(this.data, 6, 2);
      this.hotData = newData.data;
      this.generateTable(newData);
    }
    if (field === ABSOLUTE) {
      this.hotData = this.data.data;
      this.generateTable(this.data);
    }
  }

  calculateRevenue(data, power, decimal) {
    let newData = JSON.parse(JSON.stringify(data));
    if (newData) {
      newData.data.forEach((element) => {
        Object.entries(element).forEach(([key, value]) => {
          if (this.isNumber(value)) {
            const newValue: any = Number(value);
            element[key] = (newValue / Math.pow(10, power)).toFixed(decimal);
          }
        });
      });
    }
    return newData;
  }

  generateTable(data) {
    if (data && !this.isCollapsed) {
      const currentMonth = getCurrentMonth(this.selectedFinancialYear);
      let columnArray = [];
      let me = this;
      this.hotSettings = {
        colHeaders: data.columnHeaders,
        data: data.data,
        columns: data.columns,
        stretchH: "all",
        preventOverflow: "horizontal",
        formulas: false,
        fixedColumnsLeft: 1,
        rowHeights: function (row) {
          return "auto";
        },
        className: "htCenter htMiddle",
        cell: [
          { row: 0, col: 0, className: "htLeft" },
          { row: 1, col: 0, className: "htLeft" },
          { row: 2, col: 0, className: "htLeft" },
          { row: 3, col: 0, className: "htLeft" },
        ],
        fillHandle: false,
        allowHtml: true,
        licenseKey: "non-commercial-and-evaluation",
        beforePaste: function (data1, coords) {
          return false;
        },
        afterGetColHeader: function (col, TH) {
          function applyClass(elem, className) {
            if (!Handsontable.dom.hasClass(elem, className)) {
              Handsontable.dom.addClass(elem, className);
            }
          }
          if (TH.children[0].children[0].innerHTML === currentMonth) {
            applyClass(TH, "color2");
          } else {
            applyClass(TH, "color1");
          }
          columnArray.push({
            col: col,
            name: TH.children[0].children[0].innerHTML,
          });
        },
        cells: (row, col) => {
          let column = null;
          columnArray.forEach((element) => {
            if (element.col === col) {
              column = element;
              return;
            }
          });
          let cp: any = {};
          if (column) {
            const hot = me.hotRegisterer.getInstance(me.hotID);

            let celldata = null;
            if (col !== 13) {
              celldata = me.data.data[row][column.name];
            } else if (col === 13) {
              celldata = hot.getDataAtCell(row, col);
            }
            if (celldata < 0) {
              hot.setCellMeta(row, col, "valid", false);
            } else {
              hot.setCellMeta(row, col, "valid", true);
            }
          }
          if (me.selectedRevenue === LAKHS || me.selectedRevenue === MILLIONS) {
            cp.numericFormat = {
              pattern: "0,00.0",
            };
          } else if (me.selectedRevenue === CRORES) {
            cp.numericFormat = {
              pattern: "0,00.00",
            };
          } else {
            cp.numericFormat = {
              pattern: {
                thousandSeparated: true,
                mantissa: 0,
              },
            };
          }
          if (col === 0) {
            cp.className = "nonEditableMonth htLeft firstChild";
          } else {
            if (column) {
              const hot = me.hotRegisterer.getInstance(me.hotID);

              let cellData = null;
              if (col !== 13) {
                cellData = me.data.data[row][column.name];
              } else if (col === 13) {
                cellData = hot.getDataAtCell(row, col);
              }
              if (cellData < 0) {
                cp.className = "revenueInvalid htCenter";
              } else {
                cp.className = "volume-cells htCenter";
              }
            }
          }
          cp.readOnly = true;
          return cp;
        },
      };
      if (this.revenueHot) {
        this.revenueHot.updateHotTable(this.hotSettings);
      }
    }
    this.chRef.detectChanges();
  }

  isNumber(n) {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
  }

  collapseTableView(collapse: NgbCollapse) {
    collapse.collapsed = !collapse.collapsed;
    this.isCollapsed = collapse.collapsed;
    if (!collapse.collapsed) {
      setTimeout(() => {
        this.toggleRevenue(this.selectedRevenue);
      }, 0);
    }
  }

  isCalculating() {
    const calc = localStorage.getItem("isRevenueCalculation");
    if (calc) {
      if (calc === "no") {
        return false;
      } else if (calc === "yes") {
        return true;
      }
    } else {
      return false;
    }
  }
}

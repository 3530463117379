import { ThrowStmt } from '@angular/compiler';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, ignoreElements, map, shareReplay } from 'rxjs/operators';
import { Timezone } from 'src/app/core/constants/languages.constants';
import { MARKETING_MANAGER } from 'src/app/core/constants/tenant.constants';
import { AdminNotificationService } from 'src/app/core/services/admin-create-notification.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ToastrMessageService } from 'src/app/core/services/toast-message.service';


const FILTER_PAG_REGEX = /[^0-9]/g;

@Component({
  selector: 'app-view-notifications',
  templateUrl: './view-notifications.component.html',
  styleUrls: ['./view-notifications.component.scss']
})
export class ViewNotificationsComponent implements OnInit {
  notificationsList:any[]=[];
  notificationsList$: Observable<any[]>;
  geoCode: any;
  geography:any;
  profile = JSON.parse(localStorage.getItem('profile'));
  defaultGeography = localStorage.getItem('defaultGeography');
  userLevel;
  tooltipData;
  @Output() markedAsRead = new EventEmitter();
  timezone = Timezone;
  page = 1;
  size = 5;
  totalRecords = 0;
  totalPages = 0;
  errorObject: any;


  constructor(private authService:AuthService,
    private toasterService:ToastrMessageService,private adminNotificationService:AdminNotificationService) {
    this.defaultGeography = localStorage.getItem('defaultGeography');
   
   }

  ngOnInit() {
    this.defaultGeography = localStorage.getItem('defaultGeography');
    this.geography = JSON.parse(localStorage.getItem('geographies'));
    const profile = JSON.parse(localStorage.getItem("profile"));
    this.geoCode = this.fetchCurrentGeo();
    this.fetchNotification(this.page-1,this.size);
    let geography=null;
    if (this.defaultGeography) {
      geography = this.defaultGeography;
    }
  }

  readNotification(id){
    if(
      this.profile.level === "Territory Manager" ||
      this.profile.level === "Zonal Manager" ||
      this.profile.level === "Strategic Business Unit" ||
      this.profile.level === "Regional Manager" ||
      this.profile.level === "Area Manager"){
    this.adminNotificationService.markAsReadNotificationUser(id, this.geoCode).subscribe(abc => {
        const a = true;
        this.markedAsRead.emit(a);
        this.fetchNotification(this.page-1,this.size);
    })
  }
  else if (
    this.profile.level === "SAP APO Admin" ||
    this.profile.level === "Profit Center" ||
    this.profile.level === "Profit Center Admin" ||
    this.profile.level === "Marketing Manager" ||
    this.profile.level === "Unconstrained Manager"
  ){
    this.adminNotificationService.markAsReadNotificationAdmin(id).subscribe(abc => {
      const a = true;
      this.markedAsRead.emit(a);
      this.fetchNotification(this.page-1,this.size);
  })
  }
  }
  fetchCurrentGeo() {
    if (this.defaultGeography && this.profile.level) {
      let geoCode = '';
      this.geography.forEach(geo => {
        if (geo.geoId === Number(this.defaultGeography)) {
          geoCode = geo.geoCode;
        }
      });
      if (this.profile.level === MARKETING_MANAGER) {
        return this.profile.ugdn;
      }      
      return `${geoCode}`;
    } else {
      return ''
    }
  }
  setTooltipData(comments) {
    this.tooltipData = comments
  }
  onPageChange(page) {
    this.page=page;
    this.fetchNotification(this.page - 1, this.size);
  }
  fetchNotification(page, size) {
  if (
    this.profile.level === "Territory Manager" ||
    this.profile.level === "Zonal Manager" ||
    this.profile.level === "Strategic Business Unit" ||
    this.profile.level === "Regional Manager" ||
    this.profile.level === "Area Manager"
  ) {
    this.errorObject = null;
    this.notificationsList$ = this.adminNotificationService
      .viewNotificationsUser(this.geoCode, page, size)
      .pipe(
        map((response) => {
          this.totalRecords = response.notifications.totalElements;
          this.totalPages = response.notifications.totalPages;
          return response.notifications.content;
        }),
        catchError((err) => {
          this.errorObject = err;
          console.log(err);
          return [];
        }),
        shareReplay()
      );
  } else if (
    this.profile.level === "SAP APO Admin" ||
    this.profile.level === "Profit Center" ||
    this.profile.level === "Profit Center Admin" ||
    this.profile.level === "Marketing Manager" ||
    this.profile.level === "Unconstrained Manager"
  ) {
    this.errorObject = null;
    this.notificationsList$ = this.adminNotificationService
      .viewNotificationsAdmin(page, size)
      .pipe(
        map((response) => {
          this.totalRecords = response.notifications.totalElements;
          this.totalPages = response.notifications.totalPages;
          return response.notifications.content;
        }),
        catchError((err) => {
          this.errorObject = err;
          console.log(err);
          return [];
        }),
        shareReplay()
      );
  }
  }
  selectPage(page: string) {
    this.page = parseInt(page, 10) || 1;
  }

  formatInput(input: HTMLInputElement) {
    input.value = input.value.replace(FILTER_PAG_REGEX, '');
  }
}

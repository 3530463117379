import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DownLoadReportsService {

  constructor(private http: HttpClient) { }


  requestDownloadReport(geoId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/report/request/${geoId}`);

  }
  fetchViewRequestedReports(geoId,pageNo,pageSize): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/report/list/${geoId}`+'?page='+pageNo+'&size='+pageSize);
  }


  fetchDownloadedReport(requestCode): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/report/download/${requestCode}`);

  }
  retryDownloadReport(requestCode): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/report/retry/${requestCode}`);
  }
  
  
  





}

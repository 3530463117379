import {
  Component,
  OnInit,
  Input,
  ViewChild,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
  ViewEncapsulation,
  TemplateRef,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { HotTableComponent, HotTableRegisterer } from "@handsontable/angular";
import Handsontable from "handsontable";
import { getCurrentMonth } from "src/app/core/helpers/getCurrentMonth.helper";
import { MONTHS } from "src/app/core/constants/month.constants";
import { NgbCollapse, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TenantService } from "src/app/core/services/tenant.service";
import { Languages } from "src/app/core/constants/languages.constants";
import { AF, MEXICO, PC, SWAL } from "src/app/core/constants/tenant.constants";
import {
  NET,
  PARTICULARS,
  PARTICULARS1,
  PARTICULARS2,
  TOTAL,
  WINDO011,
} from "src/app/core/constants/string.constants";
import { RETURN, GROSS } from "src/app/core/constants/string.constants";
import { ToastrMessageService } from "src/app/core/services/toast-message.service";
import { MatDialog, MatDialogRef } from "@angular/material";
import { SkuSplitsBasedService } from "src/app/core/services/sku-splits-based.service";
import { LoaderComponent } from "../loader/loader.component";
import { ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { DashboardApiService } from "src/app/core/services/dashboard-api.service";
import { Router } from "@angular/router";
import { FilterApiService } from "src/app/core/services/filter-api.service";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { AuthService } from "src/app/core/services/auth.service";
import { InfoDialogComponent } from "../info-dialog/info-dialog.component";
import { FISCALMONTHS } from "src/app/core/constants/fiscalMonth.constants";
import { Subject } from "rxjs/internal/Subject";
import { map } from "rxjs/internal/operators/map";
import { Subscription } from "rxjs/internal/Subscription";
import { interval } from "rxjs/internal/observable/interval";


@Component({
  selector: "app-sku-splits-based-dialog",
  templateUrl: "./sku-splits-based-dialog.component.html",
  styleUrls: ["./sku-splits-based-dialog.component.scss"],
})
export class SkuSplitsBasedDialogComponent implements OnInit {
  closeSkuDialog: boolean = true;
  @ViewChild("SkuSplitsBasedDialog", { static: false })
  SkuSplitsBasedDialog: TemplateRef<any>;
  SkuSplitsBasedDialogRef: MatDialogRef<any>;
  @ViewChild("skuSplitHott", { static: false }) skuSplitHott: HotTableComponent;
  @ViewChild("skuSplitHottDialog", { static: false })
  skuSplitHottDialog: ElementRef;
  skuLevelForecastData;
  skuLevelBaseValueForecast;
  defaultGeography = localStorage.getItem("defaultGeography");
  profile = JSON.parse(localStorage.getItem("profile"));
  changingFinYearForFilterCompAF: Subject<any> = new Subject();
  changingFinYearForFilterCompSWAL: Subject<any> = new Subject();

  @Input() levelId;
  @Input() data;
  @Input() selectedFinancialYear;
  @Input() isDataEdited;
  @Input() skuPriceMaster;
  @Input() canEditTable;
  @Input() userLevel;
  @Input() isSameUOM;
  @Input() selectedMaterialGroups;
  @Input() selectedAreas;
  @Input() selectProductLength;
  @Input() baseValueDataObject;
  @Input() threshold;
 
  @Input() financialYearList;
  @Output() dataEdited = new EventEmitter();
  @Output() updateRevenue = new EventEmitter();
  @Output() forecastType = new EventEmitter();
  @Output() finYearChange = new EventEmitter();
  @Output() editedSkuCodes = new EventEmitter();
  page = 1;
  pagesize = 5;
  first: any = 1;
  last: any = 1;
  totalSkuCount = 0;
  collectionSize = 0;
  public loaderComponent = LoaderComponent;
  editedSkuList = [];
  negativeValueFlag = false;
  languages = Languages;
  hotSettings: Handsontable.GridSettings = null;
  hotData = null;
  hotID = "SKUHot";
  monthsList: any[] = MONTHS;
  previousValue = 0;
  isCollapsed = false;
  selectedForecast = GROSS;
  NET = NET;
  tenant;
  convertedBaseValues: any;
  convertedNegativeBaseValues: any;
  negativeBaseValueDataObject: any;
  positiveBaseValueDataObject: any;
  eMonths: any;
  editableObj: any = [];
  restrictedUrlArray = [];
  forecastingSheetData: {};
  productRevenueData;
  productVolumeData: any;
  volumeTableComp: any;
  revenueTableComp: any;
  hiddenRows: any[];
  predictiveForecastData: null;
  skuY1Volume: null; 
  type: any;
  filterParamsData;
  skuChecker: boolean = true;
  selectedCheckboxes: string[] = [];
  adminGeoId: any;
  selectedFilter;
  childGeo = null; 
  authorities: any[] = [];
  geography = [];
  isUserAdmin = false;
  timerSubscription: Subscription;
  timeInterval: number = 300000;
  assignedLevel: any;
  level: any;


  constructor(
    public dialogRef: MatDialogRef<SkuSplitsBasedDialogComponent>,
    public hotRegisterer?: HotTableRegisterer,
    private chRef?: ChangeDetectorRef,
    private tenantService?: TenantService,
    private filterService?: FilterApiService,
    private SkuSplitsBasedService?: SkuSplitsBasedService,
    private renderer?: Renderer2,
    private dashboardService?: DashboardApiService,
    private router?: Router,
    private modalService?: NgbModal,
    private authService?: AuthService,
    private toastMessageService?: ToastrMessageService
  ) {
    this.assignedLevel = localStorage.getItem("assignedLevel");
    this.level = localStorage.getItem("level");
    this.levelId = this.SkuSplitsBasedService.getLevelId();
    this.filterParamsData = this.SkuSplitsBasedService.getFilterParamsData();
    this.data = this.SkuSplitsBasedService.getSkuSplitsBasedData();
    this.selectedFinancialYear =
      this.SkuSplitsBasedService.getSelectedFinancialYear();
    this.financialYearList = this.SkuSplitsBasedService.getFinacialYearList();
    this.forecastingSheetData =
      this.SkuSplitsBasedService.getAllForecastingSheetData();
    this.tenant = this.tenantService.getTenant();
    this.selectedForecast = this.tenant === MEXICO ? "" : GROSS;
    this.restrictedUrlArray = [
      "notifications?page=",
      "notifications/geography/",
      "mark-as-read",
      "notifications/sync",
      "/sync",
    ];
     this.getAllAdminForecastingData();
   }

  ngAfterViewInit() {
    // Force a reflow
    this.renderer.setStyle(document.body, "display", "none");
    this.renderer.setStyle(document.body, "display", "");
  }

  handleCloseDialog() {
    this.dialogRef.close();
    this.SkuSplitsBasedService._closeSkuSplitsBased.emit(false);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.SkuSplitsBasedService._closeSkuSplitsBased.emit('true');
    this.hotSettings = null;
     const thresholdValue = this.threshold;
    this.negativeBaseValueDataObject = JSON.parse(
      JSON.stringify(this.baseValueDataObject)
    );
    this.positiveBaseValueDataObject = JSON.parse(
      JSON.stringify(this.baseValueDataObject)
    );
    if (changes && changes.data && changes.data.currentValue) {
      if (this.baseValueDataObject && this.baseValueDataObject.data) {
        //function to calculate positive basevalues
        this.convertedBaseValues = this.calculateBaseValue(
          this.positiveBaseValueDataObject,
          thresholdValue,
          changes.data.currentValue
        );
        //function to calculate negative basevalues
        this.convertedNegativeBaseValues = this.calculateNegativeBaseValue(
          this.negativeBaseValueDataObject,
          thresholdValue,
          changes.data.currentValue
        );
      }
    }
  }
  calculateBaseValue(
    baseValueDataObject1: any,
    thresholdValue: number,
    skudata: any
  ) {
    if (baseValueDataObject1) {
      baseValueDataObject1.data.forEach((element, index) => {
        if (index >= 0) {
          Object.entries(element).forEach(([key, value], index1) => {
            if (this.isNumber(value) && index1 > 2 && index1 < 15) {
              const newValue: any = Number(value);
              element[key] =
                newValue != 0
                  ? +(newValue + (thresholdValue * newValue) / 100).toFixed(2)
                  : 0;
            }
          });
        }
      });
    }
    return baseValueDataObject1;
  }

  calculateNegativeBaseValue(
    baseValueDataObject1: any,
    thresholdValue: number,
    skudata: any
  ) {
    if (baseValueDataObject1) {
      baseValueDataObject1.data.forEach((element, index) => {
        if (index >= 0) {
          Object.entries(element).forEach(([key, value], index1) => {
            if (this.isNumber(value) && index1 > 2 && index1 < 15) {
              const newValue: any = Number(value);
              element[key] =
                newValue != 0
                  ? +(newValue - (thresholdValue * newValue) / 100).toFixed(2)
                  : 0;
            }
          });
        }
      });
    }
    return baseValueDataObject1;
  }

  ngOnInit() {
    
    this.SkuSplitsBasedService._toggleSKU.subscribe((data) => { 
    ;  
      this.fetchForecastType(data); 
     });
   let skuParamData =  this.SkuSplitsBasedService.getTmSkuList();
   this.skuPriceMaster = skuParamData.grossPrice;
    this.authorities = localStorage.getItem("authorities").split(",");
    this.dataEdited.emit(this.isDataEdited);
    this.defaultGeography = localStorage.getItem("defaultGeography");
    this.geography = JSON.parse(localStorage.getItem("geographies"));
    this.isUserAdmin = this.authService.isAdmin();
    this.type = localStorage.getItem("forecastType");
    this.timerSubscription = interval(this.timeInterval)
      .pipe(
        map(() => {
          if (this.isDataEdited) {
            this.onAutoSave();
          }
        })
      )
      .subscribe();
    this.fetchAllFinancialYears();
  }

  onAutoSave(event?) {
    this.isDataEdited = false;
    let data = [];
    let fiscalYear; //earlier code -- const fiscalYear = this.getFiscalYear();
     this.skuLevelForecastData.data.forEach((ele, index) => {
      const skuArr = [
        ele[this.languages.LABEL_PARTICULARS],
        ele[this.languages.LABEL_PARTICULARS3],
      ];
      if (this.editedSkuList.includes(skuArr.toString())) {
        fiscalYear = this.getFiscalYearForData(
          ele[this.languages.LABEL_PARTICULARS3]
        ); //sending the currents rows fin year to get proper year
        var d = new Date();
        var curFiscalYear = d.getFullYear() + "0";
        if (
          (!this.hiddenRows.includes(index) &&
            index !== 0 &&
            index !== 1 &&
            fiscalYear >= curFiscalYear) ||
          fiscalYear === d.getFullYear() - 1 + "0"
        ) {
          let dt = new Date();
          const yrVal = ele[this.languages.LABEL_PARTICULARS3].toString();
          const decade = "20";
          const cCatYear = decade.concat(yrVal);
          let monthIndex = null; 
          FISCALMONTHS.forEach((month, monthsIndex) => {
            if (month.month === getCurrentMonth({ name: cCatYear })) {
              monthIndex = monthsIndex;
              return;
            }
          });
          let obj: any = {
            skuCode: null,
            geoId: this.childGeo
              ? this.childGeo.split(",")
              : [Number(this.defaultGeography)],
            levelForecast: {},
            eventForecast: null,
          }; 
          if(this.assignedLevel === 'Territory Manager' || this.level === 'TM'){  
                let month = this.SkuSplitsBasedService.getTmFiscalMonth();
                let Month = month.map(month=> `currentYear${month}`)
                const FISCALMONTH = [
                  { "month": "currentYearJAN", "id": "10" },
                  { "month": "currentYearFEB", "id": "11" },
                  { "month": "currentYearMAR", "id": "12" },
                  { "month": "currentYearAPR", "id": "01" },
                  { "month": "currentYearMAY", "id": "02" },
                  { "month": "currentYearJUN", "id": "03" },
                  { "month": "currentYearJUL", "id": "04" },
                  { "month": "currentYearAUG", "id": "05" },
                  { "month": "currentYearSEP", "id": "06" },
                  { "month": "currentYearOCT", "id": "07" },
                  { "month": "currentYearNOV", "id": "08" },
                  { "month": "currentYearDEC", "id": "09" },
                ];
                      const filteredFiscalMonths = FISCALMONTH.filter(fiscalMonth => Month.includes(fiscalMonth.month));
                for (const [key] of Object.entries(ele)) { 
                  for (let i = 1; i <= filteredFiscalMonths.length - 1; i++) {
                    if (key == filteredFiscalMonths[i].month) {
                      obj.skuCode = ele[this.languages.LABEL_PARTICULARS];
                      Object.assign(obj.levelForecast, {
                        [fiscalYear + filteredFiscalMonths[i].id]: Number(ele[key]),
                      });
                    }
                  }
                }
                data.push(obj);
          }else{
            for (const [key] of Object.entries(ele)) { 
              for (let i = monthIndex; i <= FISCALMONTHS.length - 1; i++) {
                if (key === FISCALMONTHS[i].month || key == 'currentYear'.concat(FISCALMONTHS[i].month)) {
                  obj.skuCode = ele[this.languages.LABEL_PARTICULARS];
                  Object.assign(obj.levelForecast, {
                    [fiscalYear + FISCALMONTHS[i].id]: Number(ele[key]),
                  });
                }
              }
            }
            data.push(obj);

          }
        }
      }
    });
    if (event) {
      this.autoSubmitForecast(data, event);
    } else {
      this.autoSubmitForecast(data);
    }
  }

  autoSubmitForecast(data, event?) {
    this.dashboardService
      .autoUpdateForecastingDataSwalAf(data, this.type, this.defaultGeography)
      .subscribe(
        (res) => {
          this.isDataEdited = false;
          this.dataEdited.emit(this.isDataEdited);
          this.toastMessageService.successMessage(
            this.languages.LABEL_FORECAST_UPDATE_SUCCESSFUL_STATUS
          );
          const flagCheckAfterSaveFilterCall = true;
          //   flagCheckAfterSaveFilterCall flag added to restrict diffUOM popup  after save call
          if (event) {
            this.filter(this.filterParamsData, flagCheckAfterSaveFilterCall);
          } else {
            this.filterParamsData.params.forecastType = this.type;
            this.filter(this.filterParamsData, flagCheckAfterSaveFilterCall);
          }
        },
        (err) => {
          this.isDataEdited = true;
        }
      );
  }

  getAllAdminForecastingData( _checkCallAfterSave?: any) { 
     if(this.forecastingSheetData["levelId"] === undefined || this.forecastingSheetData["levelId"] === null || this.forecastingSheetData["levelId"] === "" ){     
       this.dashboardService.getForecastingData(  this.forecastingSheetData["filterParams"],
      this.forecastingSheetData["geoId"],).subscribe(
        (res) => {
            if (this.assignedLevel === 'Territory Manager' || this.level === 'TM') {
                this.hiddenRows = [];
            this.canEditTable = res.editable;
            this.dataEdited.emit(false);
            this.isDataEdited = false;
            this.productRevenueData = null;
            this.productVolumeData = null;
            this.chRef.detectChanges();
            this.productRevenueData = res.productRevenue;
            this.productVolumeData = res.productVolume;
            if (res.skuPredictiveForecast && res.skuLevelForecast) {
              this.predictiveForecastData = null;
              this.skuY1Volume = null;
              this.skuLevelForecastData = null;
              this.skuLevelBaseValueForecast = null;
              this.chRef.detectChanges();
              this.predictiveForecastData = res.skuPredictiveForecast;
              this.skuY1Volume = res.skuY1Volume;
              this.skuLevelForecastData = res.skuSplitForecastTm;
              this.skuLevelBaseValueForecast = res.skuLevelBaseValueForecast;
              this.threshold = res.threshold;
              this.totalSkuCount = res.totalSkuCount;
              this.pagesize = res.pageSize;
              this.first = res.first;
              this.last = res.last;
            } else {
              this.predictiveForecastData = null;
              this.skuY1Volume = null;
              this.skuLevelForecastData = null;
              this.skuLevelBaseValueForecast = null;
            }
            if (!this.isSameUOM) {
              const dialogRef = this.modalService.open(InfoDialogComponent, {
                centered: true,
              });
              dialogRef.componentInstance.description =
                this.languages.LABEL_SAME_UOM;
            }
            if (res.skuLevelForecast) {
              setTimeout(() => {
                document
                  .getElementById("splitContainerSetion")
                  .scrollIntoView();
                  
              }, 1);
            }
          } else {
             this.hiddenRows = [];
            this.canEditTable = res.editable;
            this.dataEdited.emit(false);
            this.isDataEdited = false;
            this.productRevenueData = null;
            this.productVolumeData = null;
            this.chRef.detectChanges();
            this.productRevenueData = res.productRevenue;
            this.productVolumeData = res.productVolume;
            if (res.skuPredictiveForecast && res.skuLevelForecast) {
              this.predictiveForecastData = null;
              this.skuY1Volume = null;
              this.skuLevelForecastData = null;
              this.skuLevelBaseValueForecast = null;
              this.chRef.detectChanges();
              this.predictiveForecastData = res.skuPredictiveForecast;
              this.skuY1Volume = res.skuY1Volume;
              this.skuLevelForecastData = res.skuLevelForecast;
              this.skuLevelBaseValueForecast = res.skuLevelBaseValueForecast;
              this.threshold = res.threshold;
              this.totalSkuCount = res.totalSkuCount;
              this.pagesize = res.pageSize;
              this.first = res.first;
              this.last = res.last;
            } else {
              this.predictiveForecastData = null;
              this.skuY1Volume = null;
              this.skuLevelForecastData = null;
              this.skuLevelBaseValueForecast = null;
            }
            if (!this.isSameUOM ) {
              const dialogRef = this.modalService.open(InfoDialogComponent, {
                centered: true,
              });
              dialogRef.componentInstance.description =
                this.languages.LABEL_SAME_UOM;
            }
            if (res.skuLevelForecast) {
              setTimeout(() => {
                document
                  .getElementById("splitContainerSetion")
                  .scrollIntoView();
              }, 1);
            }
          }
        },
        (err) => {
           if (err.status === 500) {
            this.productRevenueData = null;
            this.productVolumeData = null;
            this.predictiveForecastData = null;
            this.skuY1Volume = null;
            this.skuLevelForecastData = null;
            this.skuLevelBaseValueForecast = null;
            this.chRef.detectChanges();
          }
        }
      );
    }else{
      this.dashboardService
      .getAdminForecastingData(
        this.forecastingSheetData["filterParams"],
        this.forecastingSheetData["geoId"],
        this.forecastingSheetData["levelId"]
      )
      .subscribe(
        (res) => {
          if(this.assignedLevel == "Territory Manager" || this.level == "TM"){ 
            this.hiddenRows = [];
            this.canEditTable = res.editable;
            this.dataEdited.emit(false);
            this.isDataEdited = false;
            this.productRevenueData = null;
            this.productVolumeData = null;
            this.productRevenueData = res.productRevenue;
            this.productVolumeData = res.productVolume;
            this.chRef.detectChanges();
            if (res.skuPredictiveForecast && res.skuSplitForecastTm) {
              this.predictiveForecastData = null;
              this.skuY1Volume = null;
              this.skuLevelForecastData = null;
              this.skuLevelBaseValueForecast = null;
              this.chRef.detectChanges();
              this.predictiveForecastData = res.skuPredictiveForecast;
              this.skuY1Volume = res.skuY1Volume;  
              this.skuLevelForecastData = res.skuSplitForecastTm;
              this.skuLevelBaseValueForecast =
                res.skuLevelBaseValueForecast;
              this.threshold = res.threshold;
              this.totalSkuCount = res.totalSkuCount;
              this.pagesize = res.pageSize;
              this.first = res.first;
              this.last = res.last;
            } else {
              this.predictiveForecastData = null;
              this.skuY1Volume = null;
              this.skuLevelForecastData = null;
              this.skuLevelBaseValueForecast = null;
            }
            if (!this.isSameUOM && !_checkCallAfterSave) {
              const dialogRef = this.modalService.open(
                InfoDialogComponent,
                { centered: true }
              );
              dialogRef.componentInstance.description =
                this.languages.LABEL_SAME_UOM;
            }
            if (res.skuLevelForecast) {
              setTimeout(() => {
                document
                  .getElementById("splitContainerSetion")
                  .scrollIntoView();
              }, 1);
            }
          }else{
            this.hiddenRows = [];
            this.canEditTable = res.editable;
            this.dataEdited.emit(false);
            this.isDataEdited = false;
            this.productRevenueData = null;
            this.productVolumeData = null;
            this.productRevenueData = res.productRevenue;
            this.productVolumeData = res.productVolume;
            this.chRef.detectChanges();
            if (res.skuPredictiveForecast && res.skuLevelForecast) {
              this.predictiveForecastData = null;
              this.skuY1Volume = null;
              this.skuLevelForecastData = null;
              this.skuLevelBaseValueForecast = null;
              this.chRef.detectChanges();
              this.predictiveForecastData = res.skuPredictiveForecast;
              this.skuY1Volume = res.skuY1Volume;
              this.skuLevelForecastData = res.skuLevelForecast;
              this.skuLevelBaseValueForecast =
                res.skuLevelBaseValueForecast;
              this.threshold = res.threshold;
              this.totalSkuCount = res.totalSkuCount;
              this.pagesize = res.pageSize;
              this.first = res.first;
              this.last = res.last;
            } else {
              this.predictiveForecastData = null;
              this.skuY1Volume = null;
              this.skuLevelForecastData = null;
              this.skuLevelBaseValueForecast = null;
            }
            if (!this.isSameUOM && !_checkCallAfterSave) {
              const dialogRef = this.modalService.open(
                InfoDialogComponent,
                { centered: true }
              );
              dialogRef.componentInstance.description =
                this.languages.LABEL_SAME_UOM;
            }
            if (res.skuLevelForecast) {
              setTimeout(() => {
                document
                  .getElementById("splitContainerSetion")
                  .scrollIntoView();
              }, 1);
            }
          }
        },
        (err) => {
          if (err.status === 400) {
            this.router.navigate(["/admin"]);
          }
          if (err.status === 500) {
            this.productRevenueData = null;
            this.productVolumeData = null;
            this.predictiveForecastData = null;
            this.skuY1Volume = null;
            this.skuLevelForecastData = null;
            this.skuLevelBaseValueForecast = null;
            this.page = 1;
            this.chRef.detectChanges();
          }
        }
      );
    }
  }

  dataEditedEvent(isEdited) {
    this.isDataEdited = isEdited;
    this.dataEdited.emit(isEdited);
  }

  fetchForecastType(data: string) {   
    const flagCheckAfterSaveFilterCall = true;
     if (this.isDataEdited) {
      const dialogRef = this.modalService.open(ConfirmationDialogComponent, {
        centered: true,
        backdrop: "static",
        keyboard: false,
      });
      dialogRef.componentInstance.description =
        this.languages.LABEL_UNSAVED_CHANGES;
      dialogRef.componentInstance.response.subscribe((res: boolean) => {
        if (res) {
          this.onSave();
          this.type = data;
        } else {
          this.type = data;
          this.filterParamsData.params.forecastType = this.type;
          this.filter(this.filterParamsData, flagCheckAfterSaveFilterCall);
        }
      });
    } else {
      this.type = data;
      this.filterParamsData.params.forecastType = this.type;
      this.filter(this.filterParamsData, flagCheckAfterSaveFilterCall);
    }
  }
 
  filter(paramData, _checkCallAfterSave?: any) {
    this.SkuSplitsBasedService.setTmSkuList(paramData);
   this.SkuSplitsBasedService.setLevelId(this.levelId);
   if (paramData.params.sku && this.tenantService.getTenant() === "SWAL") {
     this.skuChecker = false;
     document.getElementById("productTable").style.marginTop = "140px";
   } else {
     this.skuChecker = true;
     document.getElementById("productTable").style.marginTop = "60px";
     this.selectedCheckboxes = [];
   }
   this.adminGeoId = null;

   this.filterParamsData = paramData;
   this.SkuSplitsBasedService.setFilterParamsData(this.filterParamsData);
   const filterParams = paramData.params;
   this.selectedFilter = paramData.params;
   filterParams.page = this.page;
   this.skuPriceMaster = paramData.grossPrice;
   this.childGeo = paramData.params.childGeo;
   this.selectedFinancialYear = paramData.financialYear;
   this.SkuSplitsBasedService.setSelectedFinancialYear(
     this.selectedFinancialYear
   );
   this.isSameUOM = paramData.isSameUOM;
   if (paramData.params.productBrand && paramData.params.productBrand !== "") {
     this.selectProductLength = paramData.params.productBrand.split(",");
   } else {
     this.selectProductLength = [];
   }
   if (filterParams.financialYear) {
     let geoId;
     if (this.authService.isAdmin()) {
       geoId = paramData.adminGeoId;
       this.adminGeoId = paramData.adminGeoId;
     } else {
       if (paramData.params.selectedGeoId) {
         geoId = paramData.params.selectedGeoId;
       } else {
         geoId = Number(this.defaultGeography);
       }
     }

     if (this.userLevel) {
       if (this.levelId) {
         /* set sort=false  for ascending
           set sort=true for descending */
         filterParams.sort = false;
         this.filterParamsData.params.checkboxConstants =
           this.selectedCheckboxes;
         this.SkuSplitsBasedService.setAllForecastingSheetData({
           filterParams: filterParams,
           geoId: geoId,
           levelId: this.levelId,
         });
         this.dashboardService
           .getAdminForecastingData(filterParams, geoId, this.levelId)
           .subscribe(
            (res) => {
              if(this.assignedLevel == "Territory Manager" || this.level == "TM"){ 
                this.hiddenRows = [];
                this.canEditTable = res.editable;
                this.dataEdited.emit(false);
                this.isDataEdited = false;
                this.productRevenueData = null;
                this.productVolumeData = null;
                this.productRevenueData = res.productRevenue;
                this.productVolumeData = res.productVolume;
                this.chRef.detectChanges();
                if (res.skuPredictiveForecast && res.skuSplitForecastTm) {
                  this.predictiveForecastData = null;
                  this.skuY1Volume = null;
                  this.skuLevelForecastData = null;
                  this.skuLevelBaseValueForecast = null;
                  this.chRef.detectChanges();
                  this.predictiveForecastData = res.skuPredictiveForecast;
                  this.skuY1Volume = res.skuY1Volume;  
                  this.skuLevelForecastData = res.skuSplitForecastTm;
                  this.skuLevelBaseValueForecast =
                    res.skuLevelBaseValueForecast;
                  this.threshold = res.threshold;
                  this.totalSkuCount = res.totalSkuCount;
                  this.pagesize = res.pageSize;
                  this.first = res.first;
                  this.last = res.last;
                } else {
                  this.predictiveForecastData = null;
                  this.skuY1Volume = null;
                  this.skuLevelForecastData = null;
                  this.skuLevelBaseValueForecast = null;
                }
                if (!this.isSameUOM && !_checkCallAfterSave) {
                  const dialogRef = this.modalService.open(
                    InfoDialogComponent,
                    { centered: true }
                  );
                  dialogRef.componentInstance.description =
                    this.languages.LABEL_SAME_UOM;
                }
                if (res.skuLevelForecast) {
                  setTimeout(() => {
                    document
                      .getElementById("splitContainerSetion")
                      .scrollIntoView();
                  }, 1);
                }
              }else{
                this.hiddenRows = [];
                this.canEditTable = res.editable;
                this.dataEdited.emit(false);
                this.isDataEdited = false;
                this.productRevenueData = null;
                this.productVolumeData = null;
                this.productRevenueData = res.productRevenue;
                this.productVolumeData = res.productVolume;
                this.chRef.detectChanges();
                if (res.skuPredictiveForecast && res.skuLevelForecast) {
                  this.predictiveForecastData = null;
                  this.skuY1Volume = null;
                  this.skuLevelForecastData = null;
                  this.skuLevelBaseValueForecast = null;
                  this.chRef.detectChanges();
                  this.predictiveForecastData = res.skuPredictiveForecast;
                  this.skuY1Volume = res.skuY1Volume;
                  this.skuLevelForecastData = res.skuLevelForecast;
                  this.skuLevelBaseValueForecast =
                    res.skuLevelBaseValueForecast;
                  this.threshold = res.threshold;
                  this.totalSkuCount = res.totalSkuCount;
                  this.pagesize = res.pageSize;
                  this.first = res.first;
                  this.last = res.last;
                } else {
                  this.predictiveForecastData = null;
                  this.skuY1Volume = null;
                  this.skuLevelForecastData = null;
                  this.skuLevelBaseValueForecast = null;
                }
                if (!this.isSameUOM && !_checkCallAfterSave) {
                  const dialogRef = this.modalService.open(
                    InfoDialogComponent,
                    { centered: true }
                  );
                  dialogRef.componentInstance.description =
                    this.languages.LABEL_SAME_UOM;
                }
                if (res.skuLevelForecast) {
                  setTimeout(() => {
                    document
                      .getElementById("splitContainerSetion")
                      .scrollIntoView();
                  }, 1);
                }
              }
            },
             (err) => {
               if (err.status === 400) {
                 this.router.navigate(["/admin"]);
               }
               if (err.status === 500) {
                 this.productRevenueData = null;
                 this.productVolumeData = null;
                 this.predictiveForecastData = null;
                 this.skuY1Volume = null;
                 this.skuLevelForecastData = null;
                 this.skuLevelBaseValueForecast = null;
                 this.page = 1;
                 this.chRef.detectChanges();
               }
             }
           );
       } else {
         this.toastMessageService.errorMessage(
           this.languages.LABEL_LEVEL_NOT_NULL
         );
         this.router.navigate(["/admin"]);
       }
     } else {
       /* set sort=false  for ascending
           set sort=true for descending */

       filterParams.sort = false;
       //fetch api for dropdown
       this.filterParamsData.params.checkboxConstants =
         this.selectedCheckboxes;
         this.SkuSplitsBasedService.setAllForecastingSheetData({
           filterParams: filterParams,
           geoId: geoId, 
         });
        this.dashboardService.getForecastingData(filterParams, geoId).subscribe(
         (res) => { 
              if (this.assignedLevel == "Territory Manager" || this.level == "TM") {  
                this.hiddenRows = [];
             this.canEditTable = res.editable;
             this.dataEdited.emit(false);
             this.isDataEdited = false;
             this.productRevenueData = null;
             this.productVolumeData = null;
             this.chRef.detectChanges();
             this.productRevenueData = res.productRevenue;
             this.productVolumeData = res.productVolume;
             if (res.skuPredictiveForecast && res.skuLevelForecast) {
               this.predictiveForecastData = null;
               this.skuY1Volume = null;
               this.skuLevelForecastData = null;
               this.skuLevelBaseValueForecast = null;
               this.chRef.detectChanges();
               this.predictiveForecastData = res.skuPredictiveForecast;
               this.skuY1Volume = res.skuY1Volume;
               this.skuLevelForecastData = res.skuSplitForecastTm;
               this.skuLevelBaseValueForecast = res.skuLevelBaseValueForecast;
               this.threshold = res.threshold;
               this.totalSkuCount = res.totalSkuCount;
               this.pagesize = res.pageSize;
               this.first = res.first;
               this.last = res.last;
             } else {
               this.predictiveForecastData = null;
               this.skuY1Volume = null;
               this.skuLevelForecastData = null;
               this.skuLevelBaseValueForecast = null;
             }
             if (!this.isSameUOM && !_checkCallAfterSave) {
               const dialogRef = this.modalService.open(InfoDialogComponent, {
                 centered: true,
               });
               dialogRef.componentInstance.description =
                 this.languages.LABEL_SAME_UOM;
             }
             if (res.skuLevelForecast) {
               setTimeout(() => {
                 document
                   .getElementById("splitContainerSetion")
                   .scrollIntoView();
               }, 1);
             }
           } else {
              this.hiddenRows = [];
             this.canEditTable = res.editable;
             this.dataEdited.emit(false);
             this.isDataEdited = false;
             this.productRevenueData = null;
             this.productVolumeData = null;
             this.chRef.detectChanges();
             this.productRevenueData = res.productRevenue;
             this.productVolumeData = res.productVolume;
             if (res.skuPredictiveForecast && res.skuLevelForecast) {
               this.predictiveForecastData = null;
               this.skuY1Volume = null;
               this.skuLevelForecastData = null;
               this.skuLevelBaseValueForecast = null;
               this.chRef.detectChanges();
               this.predictiveForecastData = res.skuPredictiveForecast;
               this.skuY1Volume = res.skuY1Volume;
               this.skuLevelForecastData = res.skuLevelForecast;
               this.skuLevelBaseValueForecast = res.skuLevelBaseValueForecast;
               this.threshold = res.threshold;
               this.totalSkuCount = res.totalSkuCount;
               this.pagesize = res.pageSize;
               this.first = res.first;
               this.last = res.last;
             } else {
               this.predictiveForecastData = null;
               this.skuY1Volume = null;
               this.skuLevelForecastData = null;
               this.skuLevelBaseValueForecast = null;
             }
             if (!this.isSameUOM && !_checkCallAfterSave) {
               const dialogRef = this.modalService.open(InfoDialogComponent, {
                 centered: true,
               });
               dialogRef.componentInstance.description =
                 this.languages.LABEL_SAME_UOM;
             }
             if (res.skuLevelForecast) {
               setTimeout(() => {
                 document
                   .getElementById("splitContainerSetion")
                   .scrollIntoView();
               }, 1);
             }
           }
         },
         (err) => {
            if (err.status === 500) {
             this.productRevenueData = null;
             this.productVolumeData = null;
             this.predictiveForecastData = null;
             this.skuY1Volume = null;
             this.skuLevelForecastData = null;
             this.skuLevelBaseValueForecast = null;
             this.chRef.detectChanges();
           }
         }
       );
     }
   } else {
     this.productRevenueData = null;
     this.productVolumeData = null;
     this.predictiveForecastData = null;
     this.skuY1Volume = null;
     this.skuLevelForecastData = null;
     this.skuLevelBaseValueForecast = null;
     this.page = 1;
     this.toastMessageService.errorMessage(
       this.languages.LABEL_SELECT_FINANCIAL_YEAR
     );
     this.chRef.detectChanges();
   }
 }
  onSave(event?) {
    this.isDataEdited = false;
    let data = [];
    let fiscalYear; //earlier code -- const fiscalYear = this.getFiscalYear();
      this.skuLevelForecastData.data.forEach((ele, index) => {  
      const skuArr = [
        ele[this.languages.LABEL_PARTICULARS],
        ele[this.languages.LABEL_PARTICULARS3],
      ];

      if (this.editedSkuList.includes(skuArr.toString())) {
        fiscalYear = this.getFiscalYearForData(
          ele[this.languages.LABEL_PARTICULARS3]
        ); //sending the currents rows fin year to get proper year
        var d = new Date();
        var curFiscalYear = d.getFullYear() + "0";
        if (
          (!this.hiddenRows.includes(index) &&
            index !== 0 &&
            index !== 1 &&
            fiscalYear >= curFiscalYear) ||
          fiscalYear === d.getFullYear() - 1 + "0"
        ) {
          let dt = new Date();
          const yrVal = ele[this.languages.LABEL_PARTICULARS3].toString();
          const decade = "20";
          const cCatYear = decade.concat(yrVal);
          let monthIndex = null; 
          FISCALMONTHS.forEach((month, monthsIndex) => {
            if (month.month === getCurrentMonth({ name: cCatYear })) {
              monthIndex = monthsIndex;
              return;
            }
          });
          let obj: any = {
            skuCode: null,
            geoId: this.childGeo
              ? this.childGeo.split(",")
              : [Number(this.defaultGeography)],
            levelForecast: {},
            eventForecast: null,
          }; 
          if(this.assignedLevel === 'Territory Manager' || this.level === 'TM'){  
                let month = this.SkuSplitsBasedService.getTmFiscalMonth();
                let Month = month.map(month=> `currentYear${month}`)
                const FISCALMONTH = [
                  { "month": "currentYearJAN", "id": "10" },
                  { "month": "currentYearFEB", "id": "11" },
                  { "month": "currentYearMAR", "id": "12" },
                  { "month": "currentYearAPR", "id": "01" },
                  { "month": "currentYearMAY", "id": "02" },
                  { "month": "currentYearJUN", "id": "03" },
                  { "month": "currentYearJUL", "id": "04" },
                  { "month": "currentYearAUG", "id": "05" },
                  { "month": "currentYearSEP", "id": "06" },
                  { "month": "currentYearOCT", "id": "07" },
                  { "month": "currentYearNOV", "id": "08" },
                  { "month": "currentYearDEC", "id": "09" },
                ];
                      const filteredFiscalMonths = FISCALMONTH.filter(fiscalMonth => Month.includes(fiscalMonth.month));
                for (const [key] of Object.entries(ele)) {  
                  for (let i = 1; i <= filteredFiscalMonths.length - 1; i++) {
                    if (key == filteredFiscalMonths[i].month) {
                      obj.skuCode = ele[this.languages.LABEL_PARTICULARS];
                      Object.assign(obj.levelForecast, {
                        [fiscalYear + filteredFiscalMonths[i].id]: Number(ele[key]),
                      });
                    }
                  }
                }
                data.push(obj);
          }else{
            for (const [key] of Object.entries(ele)) { 
              for (let i = monthIndex; i <= FISCALMONTHS.length - 1; i++) {
                if (key === FISCALMONTHS[i].month || key == 'currentYear'.concat(FISCALMONTHS[i].month)) {
                  obj.skuCode = ele[this.languages.LABEL_PARTICULARS];
                  Object.assign(obj.levelForecast, {
                    [fiscalYear + FISCALMONTHS[i].id]: Number(ele[key]),
                  });
                }
              }
            }
            data.push(obj);

          }
        }
      }
    });
    if (event) {
      this.submitForecast(data, event);
    } else {
      this.submitForecast(data);
    }
  }

  submitForecast(data, event?) {
    this.dashboardService
      .updateForecastingDataSwalAf(data, this.type, this.defaultGeography)
      .subscribe(
        (res) => {
          this.isDataEdited = false;
          this.dataEdited.emit(this.isDataEdited);
          this.toastMessageService.successMessage(
            this.languages.LABEL_FORECAST_UPDATE_SUCCESSFUL_STATUS
          );
          const flagCheckAfterSaveFilterCall = true;
          if (event) {
            this.filter(this.filterParamsData, flagCheckAfterSaveFilterCall);
          } else {
            this.filterParamsData.params.forecastType = this.type;
            this.filter(this.filterParamsData, flagCheckAfterSaveFilterCall);
          }
        },
        (err) => {
          if (err.error.errorCode === WINDO011) {
            this.isDataEdited = false;
          } else {
            this.isDataEdited = true;
          }
        }
      );
  }

  editedSkuListUpdate(skulist) {
     this.editedSkuList = skulist;
  }

  tableFinYearUpdate(year) {
    this.updateFilterComp(year);
    if (year.length > 1) {
      const yearIDs = [];
      year.forEach((element) => {
        yearIDs.push(element.id);
      });
      const allYearString = yearIDs.join(",");
      this.filterParamsData.financialYear = year;
      this.filterParamsData.params.financialYear = allYearString;
      this.filter(this.filterParamsData);
    } else {
      this.filterParamsData.financialYear = year;
      this.filterParamsData.params.financialYear = year.id;
      this.filter(this.filterParamsData);
    }
  }

  updateFilterComp(year) {
    //using subject to change selected year in filters
    if (this.tenantService.getTenant() === SWAL) {
      this.changingFinYearForFilterCompSWAL.next(year);
    } else if (this.tenantService.getTenant() === AF) {
      this.changingFinYearForFilterCompAF.next(year);
    }
  }
  getFiscalYearForData(year) {
    var currYear = year.split("-")[0];
    var d = new Date();
    var currDecade = d.getFullYear().toString().substring(0, 2);
    var selFinYear = currDecade.concat(currYear);
    return selFinYear + "0";
  }

  fetchAllFinancialYears() {
    const level = this.SkuSplitsBasedService.getUserLevel();
    this.userLevel = level;
    let geography = "";
    if (!this.userLevel) {
      if (this.defaultGeography) {
        geography = this.defaultGeography;
      }
    }
    this.filterService.getFilterMasters(geography, level).subscribe((res) => {
      if (res.financialYears) {
        const FinancialYears = res.financialYears ? res.financialYears : [];
        const yearIDs = [];
        FinancialYears.forEach((element) => {
          yearIDs.push(element.id);
        });
        const allYearString = yearIDs.join(",");
        this.financialYearList = [
          { id: allYearString, name: "All", isCurrentFinancial: false },
        ];
        FinancialYears.forEach((element) => {
          this.financialYearList.push(element);
        });
        this.SkuSplitsBasedService.setFinacialYearList(this.financialYearList);
      }
    });
  }
 

  getFinYearBasedOnRow(finYear) {
    //getting fin year from table and returning editable month for that year
    let dt = new Date();
    const yrVal = finYear.toString();
    const decade = "20";
    const cCatYear = decade.concat(yrVal);
    return cCatYear;
  }

 

  changeOtherRevenue(totalColumns) {
    this.hotSettings = null;
    this.chRef.detectChanges();
     const lastCell = JSON.parse(localStorage.getItem("lastCell"));
    setTimeout(() => {
      const newHot = this.hotRegisterer.getInstance(this.hotID);
      if (lastCell && lastCell.length > 0) {
        newHot.selectCell(lastCell[0], lastCell[1], lastCell[2], lastCell[3]);
      }
      document.getElementById("splitContainerSetion").scrollIntoView();
    }, 0.1);
    const object1 = {
      totalColumns: totalColumns,
      forecastType: this.selectedForecast,
    };
    this.updateRevenue.emit(object1);
  }

  getLevel() {
    if (this.userLevel) {
      return this.userLevel;
    } else {
      return this.profile
        ? this.profile.level
            .match(/\b(\w)/g)
            .join("")
            .toUpperCase()
        : this.profile;
    }
  }

  isNumber(n) {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
  }

  setInputMaxLength() {
    let textarea = document.getElementsByClassName("handsontableInput");
    if (textarea && textarea[0]) {
      (<any>textarea[0]).setAttribute("maxlength", 18);
    }
  }

  getColumn(columnArray, changes) {
    let column = null;
    exit_loop: for (let columnData of columnArray) {
      if (columnData.name === changes) {
        column = columnData;
        break exit_loop;
      }
    }
    return column;
  }

  collapseTableView(collapse: NgbCollapse) {
    collapse.collapsed = !collapse.collapsed;
    this.isCollapsed = collapse.collapsed;
    if (!collapse.collapsed) {
      setTimeout(() => {
       }, 0);
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const headers = new HttpHeaders({
  Accept: 'text/csv',
});

const options = { headers,    responseType: "blob" as "json",
};

@Injectable({
  providedIn: 'root'
})
export class AdminForecastingCycleService {

    constructor(private http: HttpClient) { }

  getCycles(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/cycles`);
  }

  getPermissionsByLevel(level): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/permissions/${level}`);
  }

  closeCycle() {
    return this.http.get(`${environment.apiUrl}/api/v1/cycles/action?action=close`);
  }

  getActiveWindow() {
    return this.http.get(`${environment.apiUrl}/api/v1/windows/active`);
  }

  getActivities() {
    return this.http.get(`${environment.apiUrl}/api/v1/cycles/activities`);
  }

  downloadActivityReport(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/cycles/activities/download`,  options);
  }

}

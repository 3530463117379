import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TenantService } from '../services/tenant.service';

@Injectable({
  providedIn: 'root'
})
export class GeographyGuard implements CanActivate {

  constructor(private router: Router, private tenantService: TenantService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = localStorage.getItem('token');
    if (token) {
      const profile = JSON.parse(localStorage.getItem('profile'));
      const assignedLevel = localStorage.getItem('assignedLevel');
      const canViewGeo = localStorage.getItem('canViewGeo');
      const canViewLevel = localStorage.getItem('canViewLevel');
      const defaultGeography = localStorage.getItem('defaultGeography');
      const defaultTenant = localStorage.getItem('defaultTenant');
      const tenant = this.tenantService.getTenant();
      if (canViewGeo !== 'true') {
        if (!defaultTenant) {
          this.router.navigate(['/profit-center']);
        } else if (profile.assignedLevels.length > 1 && !assignedLevel && canViewLevel === 'true') {
          this.router.navigate(['/level']);
          return false;
        } else if (profile.hasGeography && defaultGeography) {
          this.router.navigate(["/" + tenant.toLowerCase() + "/forecasting"]);
          return false;
        } else if (!profile.hasGeography) {
          this.router.navigate(["/admin"]);
          return false;
        }
      } else {
        return true;
      }
    } else {
      this.router.navigate(['']);
    }
  }

}

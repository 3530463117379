import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, ChangeDetectorRef, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import Handsontable from 'handsontable';
import { HotTableComponent, HotTableRegisterer } from '@handsontable/angular';
import { KGLT, MTKL, PARTICULARS, PARTICULARS1, PARTICULARS2, TOTAL } from 'src/app/core/constants/string.constants';
import { getCurrentMonth } from 'src/app/core/helpers/getCurrentMonth.helper';
import { TenantService } from 'src/app/core/services/tenant.service';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { Languages } from 'src/app/core/constants/languages.constants';
import { MEXICO } from 'src/app/core/constants/tenant.constants';
import { LanguagesService } from 'src/app/core/services/languages.service';

@Component({
  selector: 'app-common-product-volume',
  templateUrl: './common-product-volume.component.html',
  styleUrls: ['./common-product-volume.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommonProductVolumeComponent implements OnInit, OnChanges {

  @ViewChild("volumeHot", { static: false }) volumeHot: HotTableComponent;

  @Input() data;
  @Input() selectedFinancialYear;
  @Input() financialYearList;

  languages = Languages;
  hotSettings: Handsontable.GridSettings = null;
  hotData = null;
  selectedVolume = KGLT;
  tenant;

  MTKL=MTKL;
  KGLT=KGLT;
  MEXICO=MEXICO;
  
  isCollapsed = false;
  hotID = 'VolumnHot';
  profile: any;
  defaultLanguage = Languages.LABEL_DEFAULT_LANG_ID;
  langaugeId: any;

  @Output() finYearChange = new EventEmitter();


  constructor(private tenantService: TenantService,
    private languageService:LanguagesService,
    private chRef: ChangeDetectorRef,
    private hotRegisterer: HotTableRegisterer) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.data && changes.data.currentValue) {
      this.tenant = this.tenantService.getTenant();
      this.hotSettings = null;
      this.toggleVolume(this.selectedVolume);
      if (this.tenant === MEXICO) {
        this.isCollapsed = true;
      }
    }
    this.profile = JSON.parse(localStorage.getItem('profile'));
  }

  ngOnInit() {
    this.tenant = this.tenantService.getTenant();
    this.profile = JSON.parse(localStorage.getItem('profile'));
  }

  toggleFinYear(year){
    if(year.id !== 'All'){
      this.finYearChange.emit(year);
    }
    else if (year.id === 'All'){
      const finYear = this.financialYearList;
      finYear.shift();
      this.finYearChange.emit(finYear);
    }
  }
  
  toggleVolume(field) {
    this.selectedVolume = field;
     for (let dataObject of this.data.data) {
      let total = null;
      for (const [key, value] of Object.entries(dataObject)) {       
        if (key !== this.languages.LABEL_PARTICULARS && key !== this.languages.LABEL_PARTICULARS1 && key !== this.languages.LABEL_PARTICULARS2 && key !== this.languages.LABEL_TOTAL) {
          total = total + Number(value);          
        }
      }
      dataObject[this.languages.LABEL_TOTAL] = total;
    }
    if (field === KGLT) {
      const newData = this.calculateVolumeInKG(this.data);
      this.hotData = newData.data;
      this.generateTable(this.data);
    }
    if (field === MTKL) {
      const newData = this.calculateVolumeInMT(this.data);
      this.hotData = newData.data;
      this.generateTable(newData);
    }
  }

  calculateVolumeInKG(data) {
    return JSON.parse(JSON.stringify(data));
  }

  calculateVolumeInMT(data) {
    let newData = JSON.parse(JSON.stringify(data));
    if (newData) {
      newData.data.forEach(element => {
        Object.entries(element).forEach(([key, value]) => {
          if (this.isNumber(value)) {
            const newValue: any = Number(value);
            element[key] = (newValue / Math.pow(10, 3)).toFixed(1);
          }
        });
      });
    }
    return newData;
  }

  generateTable(data) {
    if (data && !this.isCollapsed) {
      let me = this;
      let columnArray = [];
      const currentMonth = getCurrentMonth(this.selectedFinancialYear);
      this.hotSettings = {
        colHeaders: data.columnHeaders,
        data: data.data,
        columns: data.columns,
        stretchH: 'all',
        preventOverflow: 'horizontal',
        formulas: false,
        fixedColumnsLeft: 1,
        rowHeights: function (row) {
          return "auto";
        },
        className: 'htCenter htMiddle',
        cell: [
          { row: 0, col: 0, className: "htLeft" },
          { row: 1, col: 0, className: "htLeft" },
          { row: 2, col: 0, className: "htLeft" }
        ],
        fillHandle: false,
        allowHtml: true,
        licenseKey: 'non-commercial-and-evaluation',
        beforePaste: function (data1, coords) {
          return false;
        },
        beforeCreateRow: function (index, amount, source) {
          return false;
        },
        afterGetColHeader: function (col, TH) {
          function applyClass(elem, className) {
            if (!Handsontable.dom.hasClass(elem, className)) {
              Handsontable.dom.addClass(elem, className);
            }
          }
          if (TH.children[0].children[0].innerHTML === currentMonth) {
            applyClass(TH, 'color2');
          } else {
            applyClass(TH, 'color1');
          }
          columnArray.push({ col: col, name: TH.children[0].children[0].innerHTML });
        },
        cells: (row, col) => {
          let column = null;
          columnArray.forEach(element => {
            if (element.col === col) {
              column= element;
              return;
            }
          });
          let cp: any = {};
          if (column) {
            const hot = me.hotRegisterer.getInstance(me.hotID);

            let cellData = null;
            if (col !== 13) {
              cellData = me.data.data[row][column.name];             
            } else if (col === 13) {
              cellData = hot.getDataAtCell(row, col);
            }
            if (cellData < 0) {
              hot.setCellMeta(row, col, 'valid', false);
            } else {
              hot.setCellMeta(row, col, 'valid', true);
            }

          }
          if (me.selectedVolume === KGLT) {
            if(column && me.data.data[row][column.name]<0 && me.data.data[row][column.name] > -0.6){
              cp.numericFormat = {
                pattern: {
                  mantissa:0
                }
              }
            } else {
              cp.numericFormat = {
                pattern: {
                  thousandSeparated: true,
                  mantissa: 0
                }
              }
            } 
          } else {
            cp.numericFormat = {
              pattern: "0,00.0"
            }
          }
          if (col > 0) {
            if (column) {
              const hot = me.hotRegisterer.getInstance(me.hotID);
              let cellData = null;
              if (col !== 13) {
                
                cellData = me.data.data[row][column.name];
              } else if (col === 13) {
                cellData = hot.getDataAtCell(row, col);
              }
              if (cellData < 0) {
                cp.className = "volumeInvalid htCenter";
              } else {
                cp.className = "volume-cells htCenter";

              }

            }
          }
          if (col === 0) {
            cp.className = "firstChild htLeft";
          }
          cp.readOnly = true;
          return cp;
        }
      };
      if (this.volumeHot) {
        this.volumeHot.updateHotTable(this.hotSettings);
      }
    }
    this.chRef.detectChanges();
  }

  isNumber(n) {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
  }

  collapseTableView(collapse: NgbCollapse) {
    collapse.collapsed = !collapse.collapsed;
    this.isCollapsed = collapse.collapsed;
    if (!collapse.collapsed) {
      setTimeout(() => {
        this.toggleVolume(this.selectedVolume);
      }, 0);
    }
  }
}
